import type React from "react";

import { type ComponentProps, Image } from "@Components";

export interface AvatarProps extends ComponentProps {
	variant: 200 | 100 | 64 | 36;
	description: string;
	url: string;
}

export const Avatar: React.FC<React.PropsWithChildren<AvatarProps>> = ({
	className,
	url,
	description,
	variant,
}) => (
	<Image
		className={className}
		sx={{
			borderRadius: "rounded",
		}}
		width={variant}
		height={variant}
		fluid={false}
		alt={description}
		src={url}
	/>
);
