import type { AnchorPointType } from "components/AnchorPoint";
import type { ComponentProps } from "components/types";
import type React from "react";
import {
	Fragment,
	type MutableRefObject,
	useEffect,
	useRef,
	useState,
} from "react";

import { MarkerDiv } from "@Components";
import {
	anchorContentIsSignificantlyInView,
	anchorPointsRelationToView,
} from "../AnchorPoint/Position";
import { AnchorTabsBar } from "./AnchorTabsBar";

/**
  `window.scrollTo(behaviour: 'smooth')` takes an unknown amount of time, and doesn't run a
  callback on completion. `scrollTime` is an upper estimate for the length of time the browser
  takes to scroll.
*/
const scrollTime = 1000;

export type AnchorTabsType = {
	anchors: Array<AnchorPointType>;
	id: string;
	type: string;
};
interface AnchorPoint {
	id: string;
	element: HTMLElement;
}

export interface AnchorTabsProps
	extends Omit<ComponentProps, "id" | "data-id">,
		Pick<AnchorTabsType, "id" | "anchors"> {
	containerRef?: MutableRefObject<HTMLDivElement>;
}

// From sunrise/core/compatibility
function windowScrollTo({ left, top, behavior }: ScrollToOptions) {
	try {
		window.scrollTo({ left, top, behavior });
	} catch (e) {
		// Older browsers understand `scrollTo` but do not understand the option parameter form of
		// it. https://sentry.io/organizations/loveholidays-wz/issues/2688547427/?project=5177787
		if (e instanceof TypeError) {
			window.scrollTo(left ?? window.scrollX, top ?? window.scrollY);
		} else {
			throw e;
		}
	}
}

/**
  Anchor Tabs component to be used in the new Sanity Landing Pages.

  A tab bar of in page navigation links.

  It is used alongside a series of `AnchorPoint`s.

  A page will have one AnchorTabs component at the top, and several
  AnchorPoint components throughout. The AnchorPoints correspond to
  different sections of the page.

  The AnchorTabs is provided with a list of links corresponding to the
  AnchorPoints. Aurora will make sure that they match up correctly.

  AnchorTabs is sticky- it stays in the same place within the page. As you
  scroll into different sections it should indicate that those sections
  are current.

  To do this, it calculates a list of candidate sections. If what's
  currently selected is a candidate, it keeps the selection, otherwise
  it will use the first candidate.

  To be a candidate the section needs to either be totally on screen or
  cover at least 50% of the screen.

  You can click the links in the tab bar, and it will smoothly scroll
  to that section. To avoid repeatedly showing different sections as
  selected when we scroll past, we lock the behaviour for a short amount
  of time.

  When on mobile, or in another situation where the number of
  links exceeds the given space, the AnchorTabs are horizontally
  scrollable. When a section becomes current, it scrolls horizontally
  to make sure it is visible in the tab bar.

  When on desktop, the horizontal line under the tab bar becomes full
  width when the tab bar becomes sticky.
 */
export const AnchorTabs: React.FC<React.PropsWithChildren<AnchorTabsProps>> = ({
	className,
	anchors,
	id,
	containerRef,
}) => {
	const ids = anchors.map((l) => l.id);
	const anchorPoints = useRef<AnchorPoint[]>([]);
	const [tabBarHeight, setTabBarHeight] = useState<number | null>(null);
	const [currentLink, setCurrentLink] = useState(
		typeof window === "undefined"
			? ids[0]
			: window.location.hash.replace("#", "") || ids[0],
	);
	const currentLinkRef = useRef(currentLink);
	const currentLinkLock = useRef(0);
	const [isSticky, setIsSticky] = useState(false);

	/**
    Change the current link value if necessary
   */
	const calculateAndSetCurrentLink = () => {
		if (currentLinkLock.current > 0) {
			return;
		}
		const viewBoxBottom =
			document.body.parentElement?.clientHeight ?? window.innerHeight;
		const viewBox = {
			// Chrome needs a slight offset of 1px to counteract rounding
			top: (tabBarHeight ?? 0) - 1,
			// Do not include the tabBar's height when calculating the middle
			// because `middle` needs to be the same point as when `halfWayObserver` is triggered and this
			// does not take into account the area given for the tab bar.
			middle: viewBoxBottom / 2,
			bottom: viewBoxBottom,
		};
		const anchorPositions = anchorPoints.current.map((anchorPoint) =>
			anchorPointsRelationToView(anchorPoint.element, viewBox),
		);

		const currentLinkCandidates = anchorPoints.current
			.filter((_, index) =>
				anchorContentIsSignificantlyInView(
					anchorPositions[index],
					anchorPositions[index + 1],
				),
			)
			.map((anchorPoint) => anchorPoint.id);

		if (currentLinkCandidates.includes(currentLinkRef.current)) {
			return;
		}
		if (currentLinkCandidates.length > 0) {
			setCurrentLink(currentLinkCandidates[0]!);
			currentLinkRef.current = currentLinkCandidates[0]!;
		}
	};

	/** Block setting the current link from the scroll position for a short while. */
	const addCurrentLinkLock = () => {
		currentLinkLock.current += 1;
		setTimeout(() => {
			currentLinkLock.current -= 1;
			calculateAndSetCurrentLink();
		}, scrollTime);
	};

	// Add intersection observer callbacks to run `calculateAndSetCurrentLink` at the appropriate time.
	useEffect(() => {
		if (tabBarHeight === null) {
			return () => {};
		}
		anchorPoints.current = ids.reduce<AnchorPoint[]>((memo, id) => {
			const element = document.getElementById(id);
			if (element) {
				memo.push({ id, element });
			}

			return memo;
		}, []);
		const mainObserver = new IntersectionObserver(
			() => {
				calculateAndSetCurrentLink();
			},
			{ rootMargin: `-${tabBarHeight}px 0px 0px 0px` },
		);
		const halfWayObserver = new IntersectionObserver(
			() => {
				calculateAndSetCurrentLink();
			},
			{ rootMargin: "-50% 0px 0px 0px" },
		);

		calculateAndSetCurrentLink();
		for (const { element } of anchorPoints.current) {
			mainObserver.observe(element);
			halfWayObserver.observe(element);
		}

		return () => {
			mainObserver.disconnect();
			halfWayObserver.disconnect();
		};
	}, [ids.join(","), tabBarHeight]);

	return (
		<Fragment>
			<MarkerDiv
				onScreen={(isInView) => {
					setIsSticky(!isInView);
				}}
			/>
			<AnchorTabsBar
				containerRef={containerRef}
				id={id}
				anchors={anchors}
				currentLink={currentLink}
				isSticky={isSticky}
				className={className}
				setHeight={setTabBarHeight}
				onTrigger={(id) => {
					const anchorPointElement = anchorPoints.current.find(
						(anchorPoint) => anchorPoint.id === id,
					)?.element;

					if (!anchorPointElement) {
						return;
					}

					const top =
						anchorPointElement.getBoundingClientRect().top +
						window.scrollY -
						(tabBarHeight ?? 0);

					windowScrollTo({ top, behavior: "smooth" });
					addCurrentLinkLock();
					setCurrentLink(id);
					currentLinkRef.current = id;
				}}
			/>
		</Fragment>
	);
};
