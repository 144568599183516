import type React from "react";

import { type ComponentProps, Heading, Icon, Link } from "@Components";
import type { Icons } from "@Tokens";

export interface NavigationTileProps extends ComponentProps {
	url: string;
	title: string;
	icon: Icons;
	backgroundColor: string;
}

export const NavigationTile: React.FC<
	React.PropsWithChildren<NavigationTileProps>
> = ({ url, title, icon, backgroundColor, "data-id": dataId }) => (
	<Link
		href={url}
		aria-label={title}
		sx={{
			width: "100%",
			color: "textDefault",
			textDecoration: "none",
			padding: "l",
			backgroundColor: "backgroundWhite",
			borderRadius: "8",
			display: ["flex", "block", "flex"],
			alignItems: "center",
			boxShadow: "elevationElevated",
			cursor: "pointer",
			"&:hover": {
				boxShadow: "elevationHover",
			},
		}}
		data-id={dataId}
	>
		<div
			sx={{
				width: "64px",
				minWidth: "64px",
				height: "64px",
				borderRadius: "rounded",
				backgroundColor,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around",
				marginRight: "xs",
				marginBottom: [0, "l", 0],
			}}
		>
			<Icon name={icon} size="36" color="textDefault" />
		</div>
		<div>
			<Heading as="h3" variant="mediumbold">
				{title}
			</Heading>
		</div>
	</Link>
);
