import React, {
	type FC,
	type PropsWithChildren,
	useEffect,
	useRef,
} from "react";

import type { ComponentProps } from "@Components";
import { useLazyHydration } from "@Hooks";

type SupportedEvents = keyof Pick<
	HTMLElementEventMap,
	"click" | "mouseenter" | "touchstart"
>;
type HydrateOnProps = PropsWithChildren<
	ComponentProps & { eventNames: SupportedEvents[] }
>;

export const HydrateOn: FC<HydrateOnProps> = ({
	children,
	eventNames,
	...rest
}) => {
	const [setHydrated, LazyHydrateWrapper] = useLazyHydration(
		typeof window === "undefined" ? false : !!window.hydrationFinished,
	);

	const ref = useRef<HTMLElement>(null);

	useEffect(() => {
		if (ref.current) {
			eventNames.forEach((eventName) => {
				ref.current.addEventListener(eventName, setHydrated, { once: true });
			});
		}

		return () => {
			if (ref.current) {
				eventNames.forEach((eventName) => {
					ref.current.removeEventListener(eventName, setHydrated);
				});
			}
		};
	}, [ref.current]);

	return (
		<LazyHydrateWrapper ref={ref} {...rest}>
			{children}
		</LazyHydrateWrapper>
	);
};
