import type React from "react";

import { Icon, type IconProps } from "@Components";
import type { IncrementSizes } from "@Tokens";

export type NotificationIconSize = Pick<IncrementSizes, "20" | "24" | "28">;

export interface NotificationIconProps extends IconProps {
	/*
	 * Number to be displayed, zero or undefined shows a dot
	 */
	count: number;
	/*
	 * Highest number that can be displayed, higher numbers will show this value followed by '+' sign
	 */
	visualCapValue?: number;
	/*
	 * Notification badge position, currently top or bottom (both towards the right side)
	 */
	iconPosition?: "top" | "bottom";
	/*
	 * Supports a selection of IncrementSize tokens
	 */
	size: keyof NotificationIconSize | (keyof NotificationIconSize)[];
}

export const NotificationIcon: React.FC<
	React.PropsWithChildren<NotificationIconProps>
> = ({
	count,
	iconPosition = "bottom",
	visualCapValue = 99,
	size,
	...iconProps
}) => {
	const valueDisplayed = count > visualCapValue ? `${visualCapValue}+` : count;

	return (
		<div
			sx={{
				position: "relative",
				display: "inline-flex",
			}}
		>
			<Icon
				sx={{ position: "relative" }}
				size={size}
				name={iconProps.name}
				{...iconProps}
			/>
			<div
				sx={{
					position: "absolute",
					display: "inline",
					backgroundColor: "backgroundAttentiondark",
					right: 0,
					fontWeight: "bold",
					borderRadius: "rounded",
					fontSize: Number(size) > 28 ? 12 : 10,
					color: "textLight",
					padding: count > 0 ? "0px 3px" : "5xs",
					[iconPosition]: 0,
					lineHeight: Number(size) > 28 ? "13px" : "11px",
				}}
			>
				{count > 0 && valueDisplayed}
			</div>
		</div>
	);
};
