import { Loader } from "@googlemaps/js-api-loader";
import type React from "react";
import { useEffect, useRef } from "react";

import {
	useGoogleMapsApiKey,
	usePublicPath,
	useTrackingContext,
} from "@Components";

const DEFAULT_ZOOM = 15;
const ICON_PATH = "Icons/";

export interface GoogleMapProps {
	lat: number;
	lon: number;
	zoom?: number;
	className?: string;
}

const createMap = (
	markerSrc: string,
	element: HTMLElement | null,
	{ lat, lng }: { lat: number; lng: number },
	zoom: number = DEFAULT_ZOOM,
) => {
	const map = new window.google.maps.Map(element, {
		center: { lat, lng },
		zoom,
	});

	// TODO google.maps.Marker is deprecated, use AdvancedMarkerElement instead
	// eslint-disable-next-line no-new
	new window.google.maps.Marker({
		position: { lat, lng },
		map,
		icon: markerSrc,
	});
};

export const GoogleMap: React.FC<GoogleMapProps> = ({
	lat,
	lon,
	zoom,
	className,
}) => {
	const outerContentRef = useRef<HTMLDivElement>(null);
	const googleMapsApiKey = useGoogleMapsApiKey();
	const publicPath = usePublicPath();
	const { sendEvent } = useTrackingContext();

	// TODO Find a better solution
	const markerSrc = `${publicPath}${ICON_PATH}MapMarker-external.svg`.replace(
		"design-system",
		"sunrise/public",
	);

	useEffect(() => {
		const loader = new Loader({
			apiKey: googleMapsApiKey,
			version: "weekly",
		});

		const mapDOMElement = outerContentRef.current;
		loader
			.importLibrary("maps")
			.then(() => {
				createMap(markerSrc, mapDOMElement, { lat, lng: lon }, zoom);
				sendEvent({
					event: "Map",
					payload: {
						action: "loaded",
					},
				});
			})
			.catch((error) => {
				sendEvent({
					event: "Map",
					payload: {
						action: "error",
						error: String(error),
					},
				});
			});
	}, [googleMapsApiKey, markerSrc, lat, lon, zoom, sendEvent]);

	return (
		<div
			ref={outerContentRef}
			className={className}
			sx={{
				// hide the dialog box that appears when clicking on a POI
				"div[role=dialog]": {
					display: "none !important",
				},
				"div[role=dialog]+div": {
					display: "none !important",
				},
			}}
		/>
	);
};
