import type React from "react";

import {
	type ComponentProps,
	TriggerButton,
	type TriggerButtonProps,
} from "@Components";
import { Tag } from "../Tag/Tag";

export interface FilterTagProps extends ComponentProps {
	disabled?: TriggerButtonProps["disabled"];
	eventLabel?: TriggerButtonProps["eventLabel"];
	onTrigger: TriggerButtonProps["onTrigger"];
	trackingAction?: TriggerButtonProps["trackingAction"];
}

export const FilterTag: React.FC<React.PropsWithChildren<FilterTagProps>> = ({
	children,
	"data-id": dataId,
	disabled = false,
	eventLabel,
	onTrigger,
	trackingAction,
}) => (
	<TriggerButton
		data-id={dataId}
		disabled={disabled}
		eventLabel={eventLabel}
		onTrigger={onTrigger}
		sx={
			disabled
				? {}
				: {
						"&:hover > *, &:focus > *": {
							backgroundColor: "tagprimaryHover",
						},
						"&:active > *": {
							backgroundColor: "tagprimaryPressed",
						},
					}
		}
		trackingAction={trackingAction}
	>
		<Tag
			sx={
				disabled
					? {
							backgroundColor: "tagprimaryDisabledlight",
							color: "textDisabled",
						}
					: {}
			}
		>
			{children}
		</Tag>
	</TriggerButton>
);
