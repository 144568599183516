import type React from "react";
import { type AnchorHTMLAttributes, forwardRef, useCallback } from "react";

import {
	type ComponentProps,
	TriggerButton,
	type TriggerButtonProps,
} from "@Components";
import {
	DOMInteractionEvent,
	useRouterLinkContext,
	useTrackingContext,
} from "@Providers";

export interface LinkProps
	extends AnchorHTMLAttributes<HTMLAnchorElement>,
		Pick<TriggerButtonProps, "disabled">,
		ComponentProps {
	/**
	 * Uses SPA navigation when it's `true`
	 */
	internal?: boolean;

	/**
	 * When `true` use a `<a href` element.
	 * When `false`, use Javascript for the navigation.
	 * Set to `false` to prevent crawlers and other bots trivially following and indexing the link.
	 */
	asAnchor?: boolean;

	href: string;
}

export const Link = forwardRef<any, LinkProps>(
	(
		{
			internal = false,
			asAnchor = true,
			href,
			onClick,
			trackingAction,
			eventLabel,
			...rest
		},
		ref,
	) => {
		const { trackEvent } = useTrackingContext();
		const { Link: RouterLink, historyPush, useHref } = useRouterLinkContext();

		const resolvedHref = useHref(href);

		const onClickHandler = useCallback(
			(e: any) => {
				if (onClick) {
					onClick(e);
				}
				if (trackingAction) {
					trackEvent(DOMInteractionEvent.CLICK, trackingAction, eventLabel);
				}
			},
			[onClick, trackingAction, eventLabel],
		);

		if (asAnchor) {
			return internal ? (
				<RouterLink ref={ref} to={href} onClick={onClickHandler} {...rest} />
			) : (
				// eslint-disable-next-line jsx-a11y/anchor-has-content
				<a ref={ref} href={resolvedHref} onClick={onClickHandler} {...rest} />
			);
		}

		return (
			<TriggerButton
				ref={ref}
				aria-label={rest["aria-label"]}
				data-id={rest["data-id"]}
				onTrigger={(e) => {
					if (onClick !== undefined) {
						onClick(e as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
					}
					if (internal) {
						historyPush(href);
					} else {
						window.location.href = resolvedHref;
					}
				}}
				role={(rest.role as TriggerButtonProps["role"]) ?? "button"}
				className={rest.className}
				disabled={rest.disabled}
				eventLabel={eventLabel}
				trackingAction={trackingAction}
			>
				{rest.children}
			</TriggerButton>
		);
	},
);
