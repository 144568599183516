import type React from "react";
import { Fragment } from "react";

import type { ComponentProps } from "@Components";
import { type TooltipProps, useTooltip } from "@Hooks";
import { useTranslation } from "@Providers/TranslationProvider";

export const Tooltip: React.FC<
	React.PropsWithChildren<TooltipProps & ComponentProps>
> = ({
	"data-id": dataId,
	className,
	content,
	children,
	alwaysOpened = false,
	disableTouch = false,
	altText,
	delay,
}) => {
	const { t } = useTranslation();
	const { triggerProps, portal } = useTooltip({
		content,
		alwaysOpened,
		disableTouch,
		delay,
	});

	return (
		<Fragment>
			<button
				data-id={dataId}
				className={className}
				type="button"
				title={altText ?? t("moreInformation")}
				{...triggerProps}
			>
				{children}
			</button>
			{portal}
		</Fragment>
	);
};
