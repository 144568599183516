import type React from "react";

import { Button, type ComponentProps, Label } from "@Components";
import { useTranslation } from "@Providers/TranslationProvider";

export interface StepperProps extends Omit<ComponentProps, "as"> {
	value: number;
	minValue: number;
	maxValue: number;
	onChange?: (value: number) => void;
}

export const Stepper: React.FC<React.PropsWithChildren<StepperProps>> = ({
	onChange,
	value,
	minValue,
	maxValue,
	"data-id": dataId,
	...restProps
}) => {
	const { t } = useTranslation();
	const clampedValue = Math.max(minValue, Math.min(maxValue, value));
	const incrementButtonDataId = dataId
		? `${dataId}-increment-btn`
		: "increment-btn";
	const decrementButtonDataId = dataId
		? `${dataId}-decrement-btn`
		: "decrement-btn";

	const increment = () => {
		const nextCount = clampedValue < maxValue ? clampedValue + 1 : clampedValue;

		if (nextCount !== clampedValue && onChange) {
			onChange(nextCount);
		}
	};

	const decrement = () => {
		const nextCount = clampedValue > minValue ? clampedValue - 1 : clampedValue;

		if (nextCount !== clampedValue && onChange) {
			onChange(nextCount);
		}
	};

	return (
		<div
			sx={{
				display: "flex",
				alignItems: "center",
			}}
			data-id={dataId}
			{...restProps}
		>
			<Button
				size="36"
				variant="Outline"
				icon="Actions/AdjustRemove"
				onClick={decrement}
				disabled={clampedValue === minValue}
				ariaLabel={t("decrement")}
				data-id={decrementButtonDataId}
			/>
			<Label
				variant="medium"
				sx={{
					display: "flex",
					justifyContent: "center",
					marginX: "2xs",
					width: 32,
				}}
			>
				{clampedValue}
			</Label>
			<Button
				size="36"
				variant="Outline"
				icon="Actions/AdjustAdd"
				onClick={increment}
				disabled={clampedValue === maxValue}
				ariaLabel={t("increment")}
				data-id={incrementButtonDataId}
			/>
		</div>
	);
};
