import { useTheme } from "@emotion/react";
import type React from "react";
import {
	Fragment,
	type ImgHTMLAttributes,
	useCallback,
	useRef,
	useState,
} from "react";
import type { SxStyleProp } from "theme-ui";

import type { ComponentProps } from "@Components";
import { useInView } from "@Hooks";
import { useHelmetComponentContext } from "@Providers";
import { type Responsive, mergeRefs } from "@Utils";
import {
	EMPTY_IMAGE,
	getMaxWidthQueryForBreakpoint,
	getPreloadMedia,
	getSrcSet,
	makeFastlyImageUrl,
} from "./imageHelpers";

export type ResponsiveImageSize = number | [number, number, number];

type Fit = "crop" | "bounds";

type ObjectFit = Responsive<
	"contain" | "cover" | "fill" | "none" | "scale-down"
>;

export interface ImageProps
	extends Omit<ImgHTMLAttributes<HTMLImageElement>, "width" | "height">,
		ComponentProps {
	/**
	 * Width of image in pixels.
	 *
	 * Used to request an appropriate image resolution to download.
	 *
	 * The display-width of the image is determined by CSS rules, and the size of the viewport.
	 * The image download should start before the display-width is available.
	 *
	 * Therefore we need to make an approximate guess at the display-width.
	 * Use array notation for multiple breakpoints.
	 */
	width: ResponsiveImageSize;
	/**
	 * Height of image in pixels.
	 *
	 * Used to request an appropriate image resolution to download.
	 *
	 * The display-height of the image is determined by CSS rules, and the size of the viewport.
	 * The image download should start before the display-height is available.
	 *
	 * Therefore we need to make an approximate guess at the display-height.
	 * Use array notation for multiple breakpoints.
	 */
	height?: ResponsiveImageSize;
	alt: string; // override to always provide alt tag
	/**
	 * Delay loading the image until it is in the viewport.
	 */
	lazy?: boolean;
	/**
	 * Whether or not the image should fill it's container.
	 */
	fluid?: boolean;
	fit?: Fit;
	fluidHeight?: number | `${number}%`;
	onLoadCallback?: (e: HTMLImageElement) => void;
	objectFit?: ObjectFit;
	/**
	 * The quality parameter to pass through to Fastly image optimiser. This should be a number
	 * between 1 and 100.
	 *
	 * The quality parameter enables control over the compression level for lossy file-formatted images.
	 *
	 * See https://developer.fastly.com/reference/io/quality/ for reference.
	 */
	quality?: ResponsiveImageSize;
	dpr?: number;

	/**
	 * The explicit fetch priority for the image.
	 * Default behaviour is `high` for non-lazy images
	 */
	fetchPriority?: "high" | "low" | "auto";

	/**
	 * Margin to apply to the image when using lazy loading.
	 *
	 * See https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/rootMargin
	 */
	lazyLoadMargin?: IntersectionObserverInit["rootMargin"];
}

const imageStyles = (
	fluid: boolean,
	width: ResponsiveImageSize,
	height?: ResponsiveImageSize,
	objectFit: ObjectFit = "cover",
): SxStyleProp =>
	fluid
		? {
				width: "100%",
				height: "100%",
				position: "absolute",
				top: 0,
				left: 0,
				objectFit,
			}
		: {
				maxWidth: width,
				maxHeight: height,
			};

type FluidWrapperProps = {
	fluid?: boolean;
	height?: ResponsiveImageSize | `${number}%`;
} & ComponentProps;

const FluidWrapper: React.FC<React.PropsWithChildren<FluidWrapperProps>> = ({
	children,
	height,
	className,
	fluid,
}) => {
	if (fluid && height) {
		return (
			<div
				className={className}
				sx={{
					height: 0,
					paddingBottom: height,
					position: "relative",
					overflow: "hidden",
				}}
			>
				{children}
			</div>
		);
	}

	if (!height) {
		return <div className={className}>{children}</div>;
	}

	return children;
};

const getResponsiveSizeValue = (
	value: number | number[] | undefined,
	index: number,
): number | undefined => {
	if (value) {
		return Array.isArray(value) ? value[index] : value;
	}

	return undefined;
};

/**
 * Image component for displaying images
 */
export const Image: React.FC<React.PropsWithChildren<ImageProps>> = ({
	className,
	"data-id": dataId,
	src,
	alt,
	width,
	height,
	fit,
	lazy = true,
	fluid = true,
	fluidHeight,
	fetchPriority,
	onLoadCallback,
	onError,
	objectFit,
	quality,
	dpr,
	lazyLoadMargin = "200px",
}) => {
	const { breakpoints } = useTheme();
	const [isImgInView, setIsImgInView] = useState(!lazy);
	const refImg = useRef<HTMLImageElement>(null);
	const { HelmetComponent } = useHelmetComponentContext();

	const ref = useInView(
		(isInView) => {
			if (isInView) {
				setIsImgInView(true);
			}
		},
		{
			disabled: !lazy,
			triggerOnlyOnce: true,
			threshold: 0,
			rootMargin: lazyLoadMargin,
		},
	);

	const onLoaded = useCallback(
		(e: any) => {
			if (!e.target || e.target.src === EMPTY_IMAGE) {
				return;
			}
			if (onLoadCallback) {
				onLoadCallback(e.target);
			}
		},
		[refImg?.current, isImgInView],
	);

	let calculatedFit: Fit;

	if (fit) {
		calculatedFit = fit;
	} else {
		calculatedFit = fluid ? "crop" : "bounds";
	}

	const isRealImage = !lazy || isImgInView;

	if (Array.isArray(width)) {
		const imgSrc = makeFastlyImageUrl(
			src,
			calculatedFit,
			getResponsiveSizeValue(width, breakpoints.length) as number,
			getResponsiveSizeValue(height, breakpoints.length),
			getResponsiveSizeValue(quality, breakpoints.length),
			dpr,
		);

		return (
			<FluidWrapper
				height={fluidHeight || height}
				className={className}
				fluid={fluid}
			>
				<picture data-id={dataId}>
					{breakpoints.map((breakpoint, i) => {
						const imgSrc = makeFastlyImageUrl(
							src,
							calculatedFit,
							getResponsiveSizeValue(width, i) as number,
							getResponsiveSizeValue(height, i),
							getResponsiveSizeValue(quality, i),
						);

						const srcSet = getSrcSet(
							imgSrc,
							getResponsiveSizeValue(width, i) as number,
						);

						const srcSetProps = isRealImage
							? { srcSet }
							: { "data-srcset": srcSet };

						return (
							<Fragment key={i}>
								<source
									{...srcSetProps}
									media={getMaxWidthQueryForBreakpoint(breakpoint)}
								/>
								{!lazy && (
									<HelmetComponent>
										<link
											rel="preload"
											as="image"
											href={imgSrc}
											media={getPreloadMedia(breakpoints, breakpoint, i)}
										/>
									</HelmetComponent>
								)}
							</Fragment>
						);
					})}

					<Fragment>
						<img
							onLoad={onLoaded}
							ref={mergeRefs([ref, refImg])}
							sx={
								height
									? imageStyles(fluid, width, height, objectFit)
									: undefined
							}
							className={fluid && !height ? className : undefined}
							alt={alt}
							src={isRealImage ? imgSrc : EMPTY_IMAGE}
							onError={onError}
							{...(!lazy && !fetchPriority && { fetchpriority: "high" })}
							{...(!!fetchPriority && { fetchpriority: fetchPriority })}
						/>
						{!lazy && (
							<HelmetComponent>
								<link
									rel="preload"
									as="image"
									href={imgSrc}
									media={`(min-width: ${
										Number.parseInt(breakpoints[breakpoints.length - 1], 10) -
										1 +
										0.1
									}px)`}
								/>
							</HelmetComponent>
						)}
					</Fragment>
				</picture>
			</FluidWrapper>
		);
	}

	const definedHeight = getResponsiveSizeValue(height, 0);
	const definedQuality = getResponsiveSizeValue(quality, 0);

	const imgSrc = makeFastlyImageUrl(
		src,
		calculatedFit,
		width as number,
		definedHeight as number,
		definedQuality,
	);

	return (
		<FluidWrapper
			height={fluidHeight || height}
			className={className}
			fluid={fluid}
		>
			<img
				onLoad={onLoaded}
				className={!fluid ? className : undefined}
				ref={mergeRefs([ref, refImg])}
				sx={imageStyles(fluid, width, height, objectFit)}
				alt={alt}
				data-id={dataId}
				width={width}
				height={definedHeight}
				src={isRealImage ? imgSrc : EMPTY_IMAGE}
				onError={onError}
				{...(!lazy && !fetchPriority && { fetchpriority: "high" })}
				{...(!!fetchPriority && { fetchpriority: fetchPriority })}
			/>
			{!lazy && (
				<HelmetComponent>
					<link rel="preload" as="image" href={imgSrc} />
				</HelmetComponent>
			)}
		</FluidWrapper>
	);
};
