import type { ComponentProps } from "components/types";
import type React from "react";
import { type ReactNode, memo } from "react";
import type { SxStyleProp } from "theme-ui";

interface SeperatorVariants {
	dot: SxStyleProp;
	chevron: SxStyleProp;
	pipe: SxStyleProp;
	comma: SxStyleProp;
	none: SxStyleProp;
}

const seperatorStyles: SeperatorVariants = {
	dot: {
		content: "'\u00b7'",
		fontSize: "xxl",
		color: "textDefault",
		paddingLeft: "4xs",
		verticalAlign: "middle",
	},
	chevron: {
		marginX: "4xs",
		display: "inline-block",
		content: "''",
		padding: "5xs",
		borderWidth: "activeStrokeWeight",
		borderColor: "flightCardSeparator",
		borderStyle: "solid solid none none",
		transform: "rotate(45deg)",
		position: "relative",
		top: "-2px",
	},
	pipe: {
		content: "'|'",
		paddingLeft: "4xs",
		color: "brand",
	},
	comma: {
		content: "','",
		paddingLeft: 0,
		color: "textDefault",
	},
	none: {
		content: '""',
		paddingLeft: "4xs",
	},
};

export interface InlineListProps extends ComponentProps {
	items?: ReactNode[];
	separator?: keyof SeperatorVariants;
}

export const InlineList: React.FC<React.PropsWithChildren<InlineListProps>> =
	memo(({ items = [], className, separator = "pipe" }) => (
		<ol className={className}>
			{items.map((item, index) => (
				<li
					key={index}
					sx={{
						display: "inline-block",
						"&:not(:last-child)": {
							paddingRight: "4xs",
							"&::after": {
								...seperatorStyles[separator],
							},
						},
					}}
				>
					{item}
				</li>
			))}
		</ol>
	));
