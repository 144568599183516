const MIN_DURATION = 3000;

const MIN_DURATION_ACTION = 5000;

/**
 * Enforces a duration of at least 3000 with no action or 5000 when an action is passed
 */
export const toAccessibleDuration = (
	duration: number = MIN_DURATION,
	withAction: boolean,
) => {
	if (withAction) {
		return Math.max(duration, MIN_DURATION_ACTION);
	}

	return Math.max(duration, MIN_DURATION);
};
