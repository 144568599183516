import type React from "react";
import { useMemo } from "react";
import type { SxStyleProp } from "theme-ui";

import {
	Image,
	type ImageType,
	type ResponsiveImageSize,
	TriggerButton,
	decimalHash,
} from "@Components";

export type SubGridType =
	| "one"
	| "vertical_two"
	| "horizontal_two"
	| "three_left"
	| "three_right";
export type SubGridConfig = { type: SubGridType; size: number };
export type PreparedImagesForGrid = {
	id: number;
	image: ImageType;
};

export type SubGrid = {
	type: SubGridType;
	images: PreparedImagesForGrid[];
};

export const gridMapping: SubGridConfig[] = [
	{ type: "one", size: 1 },
	{ type: "vertical_two", size: 2 },
	{ type: "horizontal_two", size: 2 },
	{ type: "three_left", size: 3 },
	{ type: "three_right", size: 3 },
];

/**
 * Transforms a flat array of images to a format that is usable to build a masonry
 * grid. For example, if you have flat array [img, img, img, img, ..., img] it will be
 * output to
 * [ {type: 'one', images: [img]}, {type: 'four', images: [img, img, img, img]}, ... ]
 */
export function transformImagesIntoGrid(images: ImageType[]): SubGrid[] {
	if (!images.length) {
		return [];
	}

	const preparedImages: PreparedImagesForGrid[] = images.map(
		(image, index) => ({
			id: index,
			image,
		}),
	);

	// Top image must always be full height and width
	const results: SubGrid[] = [
		{
			type: "one",
			images: [preparedImages[0]],
		},
	];

	// drain copyImages into results as grid nodes
	let i = 1;
	while (i < preparedImages.length) {
		const unsortedImagesSize = preparedImages.length - i;
		const availableNodes = gridMapping.filter(
			({ type, size }) =>
				(type !== results[results.length - 1].type ||
					/*
            When we on the last image for sorting the previous rule should be exclude,
            because if we had 1 image grid before we not choose it and not sorted one more
            image. Because of that need || with second condition.
            Example grid from 2 images. First image will have always type 'one'
          */
					unsortedImagesSize === 1) &&
				unsortedImagesSize >= size,
		);

		/**
		 * Using decimalHash function to generate various masonry grid configuration
		 * based on image index and total number of images.
		 */
		const imageDecimalHash = decimalHash(`image_${i * preparedImages.length}`);
		const currentGridNode =
			availableNodes[Math.floor(imageDecimalHash * availableNodes.length)];

		results.push({
			type: currentGridNode.type,
			images: preparedImages.slice(i, i + currentGridNode.size),
		});

		i += currentGridNode.size;
	}

	return results;
}

interface MasonryGridProps {
	images: ImageType[];
	onClickImage: (id: number) => void;
	quality?: number;
}

const gridStylesMapping: Record<SubGridType, SxStyleProp> = {
	one: {
		gridTemplateAreas: `
      "image1 image1"
      "image1 image1"
    `,
	},
	vertical_two: {
		gridTemplateAreas: `
      "image1 image2"
      "image1 image2"
    `,
	},
	horizontal_two: {
		gridTemplateAreas: `
      "image1 image2"
    `,
	},
	three_left: {
		gridTemplateAreas: `
      "image1 image2"
      "image1 image3"
    `,
	},
	three_right: {
		gridTemplateAreas: `
      "image1 image3"
      "image2 image3"
    `,
	},
};

const getImageWidth = (
	index: number,
	sectionType: SubGridType,
): ResponsiveImageSize => {
	if (
		sectionType === "horizontal_two" ||
		(sectionType === "three_left" && index > 0) ||
		(sectionType === "three_right" && index < 2)
	) {
		return [250, 400, 500];
	}

	return [450, 600, 750];
};

const getImageHeight = (
	index: number,
	sectionType: SubGridType,
): ResponsiveImageSize => {
	if (
		sectionType === "horizontal_two" ||
		(sectionType === "three_left" && index > 0) ||
		(sectionType === "three_right" && index < 2)
	) {
		return [150, 250, 275];
	}

	return [275, 400, 500];
};

export const MasonryGrid: React.FC<MasonryGridProps> = ({
	images,
	onClickImage,
	quality,
}) => {
	if (!images.length) {
		return null;
	}

	const transformedImages = useMemo(
		() => transformImagesIntoGrid(images),
		[images],
	);

	return (
		<div
			sx={{
				touchAction: "pan-x pan-y",
			}}
		>
			{transformedImages.map((section, sectionIndex) => (
				<section
					key={sectionIndex}
					sx={{
						position: "relative",
						overflow: "hidden",
						marginBottom: ["4xs", "3xs"],
						paddingBottom: "55%",
						...(section.type === "horizontal_two" && {
							paddingBottom: "26%",
						}),
					}}
				>
					<div
						sx={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							display: "grid",
							gap: ["4xs", "3xs"],
							gridTemplateColumns: "1fr 1fr",
							gridTemplateRows: "auto auto",
							overflow: "hidden",
							"& > *": {
								backgroundColor: "#E6E3DC",
								width: "100%",
							},
							...gridStylesMapping[section.type],
							...(section.type === "horizontal_two" && {
								gridTemplateRows: "auto",
							}),
						}}
					>
						{section.images.map(({ id, image }, imageIndex) => (
							<TriggerButton
								key={id}
								onTrigger={() => onClickImage(id)}
								data-id={`masonry-grid-image-${id}`}
								sx={{
									gridArea: `image${imageIndex + 1}`,
									position: "relative",
									overflow: "hidden",
									"&:hover": {
										filter: "brightness(85%)",
										transition: "all 0.3s",
									},
								}}
							>
								<Image
									src={image.url}
									alt={image.description}
									width={getImageWidth(imageIndex, section.type)}
									height={getImageHeight(imageIndex, section.type)}
									lazy={sectionIndex > 1}
									lazyLoadMargin="400px"
									sx={{
										position: "absolute",
										height: "100%",
										width: "100%",
									}}
									quality={quality}
								/>
							</TriggerButton>
						))}
					</div>
				</section>
			))}
		</div>
	);
};
