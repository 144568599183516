import type React from "react";

import { Rating, type RatingProps } from "../Rating";

export type TripadvisorRatingProps = Pick<
	RatingProps,
	| "className"
	| "data-id"
	| "reviewCount"
	| "rating"
	| "size"
	| "floating"
	| "variant"
>;

export const TripadvisorRating: React.FC<
	React.PropsWithChildren<TripadvisorRatingProps>
> = ({
	rating,
	reviewCount,
	"data-id": dataId = "tripadvisor-rating",
	className,
	size = "default",
	floating = true,
	variant = "inline",
}) => (
	<Rating
		rating={rating}
		reviewCount={reviewCount}
		data-id={dataId}
		className={className}
		size={size}
		floating={floating}
		variant={variant}
		brand="Tripadvisor"
		brandIcon="Brands/TripAdvisor"
		filledIcon="Markers/TripadvisorFilled"
		halfIcon="Markers/TripadvisorHalf"
		emptyIcon="Markers/TripadvisorEmpty"
		ratingColor="iconTripadvisor"
	/>
);
