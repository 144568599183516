import type { BreakpointsPalette } from "@Themes";
import { URLQueryParams } from "@Utils";

export const EMPTY_IMAGE =
	"data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
export const FALLBACK_IMAGE_PATH = "Images/default-hotel-image.jpg";

export const getMaxWidthQueryForBreakpoint = (breakpoint: string) =>
	`(max-width: ${Number.parseInt(breakpoint, 10) - 1}px)`;

export const defaultFastlyQueryParams = {
	auto: "avif,webp",
	quality: "45",
	dpr: "2",
	optimize: "high",
};

export const defaultFastlyQueryParamsString = new URLSearchParams(
	defaultFastlyQueryParams,
).toString();

export function makeFastlyImageUrl(
	baseSrc = "",
	fit: "bounds" | "crop",
	width: number,
	height?: number,
	quality?: number,
	dpr?: number,
) {
	if (!baseSrc) {
		return EMPTY_IMAGE;
	}

	const fastlyQueryParams = {
		...defaultFastlyQueryParams,
		fit,
		width,
		...(height && { height }),
		...(quality && { quality }),
		...(dpr && { dpr }),
	};

	const imageUrlParams = new URLQueryParams(fastlyQueryParams as any);
	const imageUrl = new URL(baseSrc);

	imageUrlParams.forEach((value, key) => {
		imageUrl.searchParams.append(key, value);
	});

	return imageUrl.toString();
}

export const getSrcSet = (url = "", width: number) => `${url} ${width}w`;

export const getPreloadMedia = (
	breakpoints: BreakpointsPalette,
	breakpoint: string,
	index: number,
) => {
	if (index === 0) {
		return getMaxWidthQueryForBreakpoint(breakpoint);
	}

	// eslint-disable-next-line max-len
	return `(min-width: ${Number.parseInt(breakpoints[index - 1], 10) + 0.1}px) and ${getMaxWidthQueryForBreakpoint(breakpoint)}`;
};
