import type React from "react";

import { PageButton } from "./PageButton";
import { Direction, PaginationChevronButton } from "./PaginationChevronButton";
import { PaginationGroupSeparator } from "./PaginationGroupSeparator";
import { getVisiblePages, groupSeparatorPageNumber } from "./getVisiblePages";

export interface PaginationProps {
	numberOfPages: number;
	currentPage: number;
	onPageSelect: (pageIndex: number) => void;
	maxNumberOfPagesToShow?: number;
}

export const Pagination: React.FC<React.PropsWithChildren<PaginationProps>> = ({
	numberOfPages,
	currentPage,
	onPageSelect,
	maxNumberOfPagesToShow = 6,
}) => {
	const pages = getVisiblePages(
		currentPage,
		numberOfPages,
		maxNumberOfPagesToShow,
	);
	const handleChevronClick = (diff: number) => onPageSelect(currentPage + diff);

	return (
		<ul
			sx={{
				display: "flex",
				paddingTop: "xs",
			}}
		>
			<PaginationChevronButton
				direction={Direction.LEFT}
				onClick={handleChevronClick}
				enabled={currentPage > 0}
			/>
			{pages.map((pageNumber) =>
				pageNumber === groupSeparatorPageNumber ? (
					<PaginationGroupSeparator key={pageNumber} />
				) : (
					<PageButton
						key={pageNumber}
						pageNumber={pageNumber}
						isCurrent={pageNumber === currentPage}
						onClick={onPageSelect}
					/>
				),
			)}
			<PaginationChevronButton
				direction={Direction.RIGHT}
				onClick={handleChevronClick}
				enabled={currentPage < numberOfPages - 1}
			/>
		</ul>
	);
};
