import type React from "react";
import { forwardRef } from "react";

import type { ComponentProps } from "../../types";

export interface RadioButtonProps
	extends React.InputHTMLAttributes<HTMLInputElement>,
		ComponentProps {}

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
	({ ...props }, ref) => (
		<input
			type="radio"
			ref={ref}
			{...props}
			sx={{
				appearance: "none",
				margin: 0,
				width: "24",
				height: "24",
				borderStyle: "solid",
				borderWidth: 2,
				borderColor: "interactiveDefault",
				borderRadius: "50%",
				"&:hover": {
					borderColor: "interactiveHover",
				},
				/*
				 * The pressed radio button is visually made up of 2 circles.
				 * The element itself is styled to appear as the outer always shown circle.
				 * The `after` pseudo element is styled to appear as the inner circle when the input is pressed.
				 */
				"&::after": {
					content: '""',
					display: "block",
					borderRadius: "50%",
					width: "12",
					height: "12",
					margin: 4,
				},
				"&:checked": {
					borderColor: "interactiveSelected",
				},
				"&:checked::after": {
					backgroundColor: "interactiveSelected",
				},
				"&:focus": {
					outlineColor: "interactiveSelected",
				},
				"&:disabled": {
					cursor: "not-allowed",
					borderColor: "interactiveDisabledlight",
					"&:hover::after": {
						backgroundColor: "transparent",
					},
					"&:checked::after": {
						backgroundColor: "interactiveDisabledlight",
					},
				},
			}}
		/>
	),
);
