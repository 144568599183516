import type React from "react";

import {
	type ComponentProps,
	Heading,
	Label,
	Paragraph,
	TrustpilotRating,
} from "@Components";
import { lineClamp } from "@Utils";

export interface TrustpilotReviewProps extends ComponentProps {
	displayName: string;
	displayStars: number;
	reviewAge: string;
	text?: string | null;
	title: string;
}

export const TrustpilotReview: React.FC<
	React.PropsWithChildren<TrustpilotReviewProps>
> = ({ className, displayName, displayStars, reviewAge, text, title }) => (
	<div
		className={className}
		sx={{
			display: "flex",
			flexDirection: "column",
			padding: "l",
			backgroundColor: "backgroundLight",
		}}
	>
		<div
			sx={{
				display: "flex",
				justifyContent: "space-between",
				marginBottom: "xs",
			}}
		>
			<TrustpilotRating rating={displayStars} />
			<Label
				variant="extrasmall"
				sx={{
					color: "textDimmedmedium",
				}}
			>
				{reviewAge}
			</Label>
		</div>

		<Heading
			variant="smallbold"
			as="h4"
			sx={{
				marginBottom: "3xs",
				...lineClamp(1),
			}}
		>
			{title}
		</Heading>

		{text && (
			<Paragraph
				variant="extrasmall"
				sx={{
					color: "textDimmedheavy",
					marginBottom: "xs",
					...lineClamp(3),
					flexGrow: 1,
				}}
			>
				{text}
			</Paragraph>
		)}

		<Label
			variant="extrasmall"
			sx={{
				color: "textDimmedmedium",
				...lineClamp(1),
			}}
		>
			{displayName}
		</Label>
	</div>
);
