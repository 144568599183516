import type React from "react";

import { type ComponentProps, Label, keyboardOutlineWithin } from "@Components";

type HTMLProps = React.InputHTMLAttributes<HTMLInputElement> & ComponentProps;

export interface SwitchProps extends HTMLProps {
	/**
	 * label above the switch
	 */
	label?: string;
	/**
	 * the default text when not checked/toggled
	 */
	offText?: string;
	/**
	 * the text when checked/toggled
	 */
	onText?: string;
}

export const Switch: React.FC<React.PropsWithChildren<SwitchProps>> = ({
	className,
	"data-id": dataId,
	label,
	checked,
	disabled = false,
	offText,
	onText,
	...props
}) => (
	<div
		sx={{
			display: "flex",
			flexDirection: "column",
		}}
	>
		{!!label && (
			<Label
				variant="small"
				sx={{
					marginBottom: "2xs",
				}}
			>
				{label}
			</Label>
		)}
		<label
			htmlFor={dataId}
			data-id={dataId}
			className={className}
			sx={{
				display: "inline-flex",
				cursor: disabled ? "not-allowed" : "pointer",
				whiteSpace: "nowrap",
			}}
		>
			<div
				sx={{
					display: "inline-block",
					width: "40px",
					height: "24",
					borderRadius: "rounded",
					padding: "5xs",
					backgroundColor: "interactiveDefault",
					"&:hover": {
						boxShadow: ({ colors }) =>
							`inset 0 0 0 100vh ${colors.interactiveHover}`,
					},
					...(checked && {
						backgroundColor: "interactiveSelected",
					}),
					...(disabled && {
						backgroundColor: "interactiveDisabledlight",
						"&:hover": {
							boxShadow: null,
						},
					}),
					...keyboardOutlineWithin,
				}}
			>
				<input
					type="checkbox"
					checked={checked}
					{...props}
					sx={{
						overflow: "hidden",
						position: "absolute",
						width: 0,
						opacity: 0,
					}}
				/>
				<div
					sx={{
						position: "relative",
						"::before": {
							content: '""',
							display: "block",
							width: "20",
							height: "20",
							borderRadius: "rounded",
							backgroundColor: "textLight",
							transition: ({ motion }) =>
								`transform ${motion.moveKangaroo.duration}ms ${motion.moveKangaroo.easing}`,
							...(checked && {
								transform: "translateX(80%)",
							}),
						},
					}}
				/>
			</div>
			{onText && offText && (
				<Label
					variant="medium"
					sx={{
						marginLeft: "3xs",
						alignSelf: "center",
						...(disabled && {
							color: "interactiveDisabledlight",
						}),
					}}
				>
					{checked ? onText : offText}
				</Label>
			)}
		</label>
	</div>
);
