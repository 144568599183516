import type React from "react";
import { type PropsWithChildren, useEffect, useRef, useState } from "react";

import { HIGHLIGHT_MARGIN } from "./ContentSwitcherTabs/ContentSwitcherTabs";
import { TabList } from "./TabList";
import { TabPanel } from "./TabPanel";
import { TabsContext } from "./TabsContext";
import type { TabChildren, TabsProps } from "./types";

type ControlledTabsProps = PropsWithChildren<
	Omit<TabsProps, "initialTabId" | "value"> & {
		activeTabId: string;
		className?: string;
		tabs: TabChildren;
	}
>;

const getCurrentTabEl = (tabsContainer: HTMLDivElement, tabIndex: string) => {
	const allTabs: HTMLElement[] = Array.from(
		tabsContainer?.querySelectorAll("[role=tab]"),
	);
	const currentTabIndex = allTabs.findIndex((el) => el.dataset.id === tabIndex);
	return allTabs[currentTabIndex];
};

export const ControlledTabs: React.FC<ControlledTabsProps> = (props) => {
	const {
		activeTabId,
		prefix,
		className,
		tabListStyles,
		tabPanelStyles,
		tabs,
		onTabChanged = () => {},
		indentedTabList = true,
		enableActiveHighlight = false,
	} = props;

	const tabListEl = useRef(null);
	const isInitialized = useRef(false);
	const [left, setLeft] = useState(0);
	const [width, setWidth] = useState(0);
	const [transitionEnabled, setTransitionEnabled] = useState(false);

	// biome-ignore  lint/correctness/useExhaustiveDependencies: should be triggered when initialized
	useEffect(() => {
		if (!enableActiveHighlight) {
			return;
		}

		if (isInitialized.current) {
			setTransitionEnabled(true);
		}
	}, [enableActiveHighlight, isInitialized.current]);

	// biome-ignore  lint/correctness/useExhaustiveDependencies: it should be run once for initialisation
	useEffect(() => {
		if (!enableActiveHighlight) {
			return;
		}

		if (!isInitialized.current) {
			const currentTab = getCurrentTabEl(tabListEl.current, activeTabId);

			setWidth(currentTab.offsetWidth / tabListEl.current.offsetWidth);
			setLeft(currentTab.offsetLeft / tabListEl.current.offsetWidth);
		}
		isInitialized.current = true;
	}, []);

	const onTabChangeFn = (tabId: string) => {
		if (enableActiveHighlight) {
			const currentTab = getCurrentTabEl(tabListEl.current, tabId);

			setTimeout(() => {
				if (currentTab) {
					setWidth(currentTab.offsetWidth / tabListEl.current.offsetWidth);
					setLeft(currentTab.offsetLeft / tabListEl.current.offsetWidth);
				}
			}, 50);
		}

		onTabChanged(tabId);
	};

	return (
		<TabsContext.Provider
			value={{
				activeTabId,
				changeTab: onTabChangeFn,
				prefix,
			}}
		>
			<section className={className} data-id={prefix}>
				<TabList
					ref={tabListEl}
					sx={{
						overflow: "auto",
						zIndex: 1,
						...tabListStyles,
						...(enableActiveHighlight && {
							":after": {
								content: '""',
								position: "absolute",
								top: 0,
								bottom: 0,
								right: 0,
								marginY: HIGHLIGHT_MARGIN,
								backgroundColor: "tagprimaryDefault",
								borderRadius: "rounded",
								left: `${left * 100}%`,
								width: `${width * 100}%`,
								transition: transitionEnabled && "left 0.2s, width 0.2s",
								zIndex: 1,
							},
						}),
					}}
					indented={indentedTabList}
				>
					{tabs.map((tab) => (
						<div key={tab.props.id} sx={{ display: "flex", zIndex: 2 }}>
							{tab}
						</div>
					))}
				</TabList>
				{tabs.map(({ props: { id, lazy, children } }) => (
					<TabPanel
						id={id}
						key={id}
						lazy={lazy}
						sx={{
							paddingTop: "s",
							...tabPanelStyles,
						}}
					>
						{children}
					</TabPanel>
				))}
			</section>
		</TabsContext.Provider>
	);
};
