import type React from "react";

import { type ComponentProps, Icon, Title } from "@Components";
import type { Icons } from "@Tokens";

export interface ContentCardTitleProps extends ComponentProps {
	title: string;
	icon?: Icons;
}

export const ContentCardTitle: React.FC<ContentCardTitleProps> = ({
	title,
	icon,
	...componentProps
}) => (
	<div
		sx={{
			display: "flex",
			alignItems: "center",
			marginBottom: "xs",
		}}
		{...componentProps}
	>
		{icon && (
			<Icon
				name={icon}
				size="48"
				sx={{
					marginRight: "xs",
				}}
			/>
		)}
		<Title
			as="h2"
			variant="xxsmall"
			sx={{
				wordBreak: "break-word",
			}}
		>
			{title}
		</Title>
	</div>
);
