import type { TooltipReturn } from "hooks/useTooltip";
import type React from "react";
import type { DOMAttributes, InputHTMLAttributes, ReactNode } from "react";
import type { SxStyleProp } from "theme-ui";

import {
	ActionButton,
	type ComponentProps,
	Icon,
	type IconProps,
	Label,
	type TriggerButtonBehaviourProps,
	getActionButtonIconVariationWidth,
} from "@Components";
import type { ColorsPalette, ThemeDefinition } from "@Themes";
import type { Icons, IncrementSizes } from "@Tokens";

interface InputFieldButtonProps
	extends TriggerButtonBehaviourProps,
		Partial<TooltipReturn["triggerProps"]> {
	icon: Icons;
}

export interface InputFieldDecorationProps
	extends Pick<InputHTMLAttributes<HTMLInputElement>, "id" | "value">,
		Pick<ComponentProps, "className" | "data-id"> {
	size: keyof IncrementSizes;
	label?: string;
	subtitle?: ReactNode;
	icon?: Icons;
	/** The state of the input field. Hover and Focus are intended for Storybook only. */
	state?: "Standard" | "Error" | "Success" | "Disabled" | "Hover" | "Focus";
	message?: ReactNode;
	button?: InputFieldButtonProps;
	onButtonAndInputContainerBlur?: DOMAttributes<HTMLElement>["onBlur"];
}

const inputBorderColor: Record<
	InputFieldDecorationProps["state"],
	keyof ColorsPalette
> = {
	Standard: "strokeDarkneutral",
	Error: "strokeCriticaldark",
	Success: "strokeSuccessdark",
	Disabled: "strokeDisabledlight",
	Hover: "strokeDark",
	Focus: "strokeDark",
};

const messageColor: Partial<
	Record<InputFieldDecorationProps["state"], keyof ColorsPalette>
> = {
	Standard: "textDimmedmedium",
	Error: "textCriticaldark",
	Success: "textSuccessdark",
};

const iconColor: (
	props: Pick<InputFieldDecorationProps, "value" | "state">,
) => keyof ColorsPalette = (props) => {
	if (props.state === "Disabled") {
		return "iconDisabledlight";
	}
	if (props.value) {
		return "iconDefault";
	}

	return "iconDimmedmedium";
};

const buttonColor: (
	props: Pick<InputFieldDecorationProps, "value" | "state">,
) => keyof ColorsPalette = ({ state, value }) => {
	switch (state) {
		case "Disabled":
			return "iconDisabledlight";
		case "Success":
			return "iconSuccessdark";
		case "Error":
			return "iconCriticaldark";
		default:
			return value ? "iconDefault" : "iconDisabled";
	}
};

const iconSize: IconProps["size"] = "20";
const getIconPaddingInner = (theme: ThemeDefinition) => theme.space["3xs"];
const getIconPaddingOuter = (theme: ThemeDefinition) => theme.space.s;
const getIconWidth = (theme: ThemeDefinition) =>
	getIconPaddingOuter(theme) +
	Number.parseInt(iconSize, 10) +
	getIconPaddingInner(theme);

const getButtonPaddingInner = (theme: ThemeDefinition) => theme.space["3xs"];
const getButtonPaddingOuter = (theme: ThemeDefinition) => theme.space.xs;
const getButtonWidth = (theme: ThemeDefinition) =>
	getButtonPaddingOuter(theme) +
	getButtonPaddingInner(theme) +
	getActionButtonIconVariationWidth(theme);

const ButtonRight: React.FC<
	React.PropsWithChildren<{
		button: InputFieldButtonProps;
		state: InputFieldDecorationProps["state"];
		value: InputFieldDecorationProps["value"];
	}>
> = ({ button: { icon, ...triggerProps }, state, value }) => (
	<ActionButton
		{...triggerProps}
		sx={{
			position: "absolute",
			right: (theme) => getButtonPaddingOuter(theme),
			top: "50%",
			transform: "translateY(-50%)",
			color: buttonColor({ state, value }),
		}}
		icon={icon}
		disabled={state === "Disabled"}
		type="Icon"
	/>
);

const placeholderColor: (
	props: Pick<InputFieldDecorationProps, "state">,
) => keyof ColorsPalette = ({ state }) =>
	state === "Disabled" ? "textDisabledlight" : "textDimmedmedium";

const inputFieldFollowingPlaceholderCommonStyles: (
	props: Pick<InputFieldDecorationProps, "icon" | "button">,
) => SxStyleProp = ({ icon, button }) => ({
	// Display the input full-width above the icons so that text selection works
	// all the way to the visible border
	position: "absolute",
	top: 0,
	left: 0,
	width: "100%",
	height: "100%",
	paddingLeft: (theme) => (icon ? getIconWidth(theme) : "s"),
	paddingRight: (theme) => (button ? getButtonWidth(theme) : "s"),

	backgroundColor: "transparent",
	borderStyle: "none",
	borderRadius: "8",
	"&": (theme: ThemeDefinition) => theme.typography.label.medium,
});

export const InputFieldDecoration: React.FC<
	React.PropsWithChildren<InputFieldDecorationProps>
> = ({
	label,
	subtitle,
	value,
	size,
	state = "Standard",
	icon,
	id,
	children,
	message,
	button,
	onButtonAndInputContainerBlur,
	className,
	"data-id": dataId,
}) => (
	<div
		sx={{
			display: "flex",
			flexDirection: "column",
		}}
		className={className}
		data-id={dataId}
	>
		{label && (
			<label
				htmlFor={id}
				sx={(theme: ThemeDefinition) => ({
					color: state === "Disabled" ? "textDisabled" : null,
					paddingBottom: subtitle ? undefined : "3xs",
					...theme.typography.label.mediumbold,
				})}
			>
				{label}
			</label>
		)}
		{subtitle && (
			<div
				sx={{
					color: state === "Disabled" ? "textDisabled" : null,
					paddingBottom: "3xs",
				}}
			>
				{subtitle}
			</div>
		)}
		<div
			sx={{
				height: size,
				backgroundColor: "backgroundWhite",
				borderRadius: "8",
				position: "relative",
				borderWidth: "outlinedStrokeWeight",
				borderStyle: "solid",
				borderColor: inputBorderColor[state],
				":hover": {
					borderColor: state === "Standard" ? inputBorderColor.Hover : null,
				},
				":focus-within": {
					outline: "none",
					borderColor: state === "Standard" ? inputBorderColor.Focus : null,
				},
			}}
			onBlur={onButtonAndInputContainerBlur}
		>
			{icon && (
				<Icon
					name={icon}
					size={iconSize}
					color={iconColor({ state, value })}
					sx={(theme) => ({
						position: "absolute",
						left: 0,
						top: "50%",
						transform: "translateY(-50%)",
						paddingLeft: getIconPaddingOuter(theme),
						width: getIconWidth(theme),
						paddingRight: getIconPaddingInner(theme),
					})}
				/>
			)}
			{children}
			{button && <ButtonRight state={state} button={button} value={value} />}
		</div>
		{message && (
			<Label
				sx={{
					marginTop: "2xs",
					color: messageColor[state],
				}}
				data-id={`${dataId}-${state}`}
				variant="small"
			>
				{message}
			</Label>
		)}
	</div>
);

type InputFieldStylesProps = Parameters<
	typeof inputFieldFollowingPlaceholderCommonStyles
>[0] &
	Parameters<typeof placeholderColor>[0] & {
		inputFieldStylesOverrides?: SxStyleProp;
	};

export const inputFieldStyles: (props: InputFieldStylesProps) => SxStyleProp = (
	props,
) => ({
	// Firefox and Webkit do not use our fonts for inputs unless this is set
	fontFamily: "inherit",

	...inputFieldFollowingPlaceholderCommonStyles(props),

	// Safari iPhone centers and shows in blue for some reason
	color: props.state === "Disabled" ? "textDisabledlight" : "textDefault",
	"::-webkit-date-and-time-value": {
		// Urgh https://simplernerd.com/js-align-text-left-ios-date-input/
		textAlign: "left",
	},

	"::placeholder": {
		color: placeholderColor(props),
		opacity: 1,
	},
	":focus": {
		outline: "none",
	},

	...(props.inputFieldStylesOverrides || {}),
});
