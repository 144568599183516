import React, { forwardRef, type InputHTMLAttributes } from "react";
import type { SxStyleProp } from "theme-ui";

import { useId } from "@Hooks";
import type { IncrementSizes } from "@Tokens";
import {
	InputFieldDecoration,
	type InputFieldDecorationProps,
	inputFieldStyles,
} from "./InputFieldDecoration";

export interface InputFieldProps
	extends Pick<
			InputFieldDecorationProps,
			| "icon"
			| "button"
			| "label"
			| "subtitle"
			| "message"
			| "value"
			| "state"
			| "className"
			| "data-id"
		>,
		Omit<
			InputHTMLAttributes<HTMLInputElement>,
			"className" | "sx" | "id" | "value" | "size" | "disabled"
		> {
	size: keyof Pick<IncrementSizes, "64" | "48">;
	inputFieldStylesOverrides?: SxStyleProp;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
	(
		{
			icon,
			button,
			label,
			subtitle,
			message,
			size,
			state = "Standard",
			value,
			className,
			"data-id": dataId,
			inputFieldStylesOverrides,
			...rest
		},
		ref,
	) => {
		const id = useId();

		return (
			<InputFieldDecoration
				icon={icon}
				button={button}
				id={id}
				label={label}
				subtitle={subtitle}
				message={message}
				size={size}
				state={state}
				value={value}
				className={className}
				data-id={dataId}
			>
				<input
					ref={ref}
					value={value}
					disabled={state === "Disabled"}
					id={id}
					sx={inputFieldStyles({
						icon,
						state,
						button,
						inputFieldStylesOverrides,
					})}
					{...rest}
				/>
			</InputFieldDecoration>
		);
	},
);
