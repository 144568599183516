import type React from "react";

import { type ComponentProps, Label } from "@Components";

export interface TagProps extends ComponentProps {}

export const Tag: React.FC<React.PropsWithChildren<TagProps>> = ({
	children,
	className,
	"data-id": dataId,
}) => (
	<Label
		className={className}
		data-id={dataId}
		variant="medium"
		sx={{
			whiteSpace: "nowrap",
			backgroundColor: "tagprimaryDefault",
			color: "tagprimaryText",
			paddingX: "2xs",
			paddingY: "3xs",
			borderRadius: "4",
			display: "inline-block",
			"&:hover": {
				backgroundColor: "tagprimaryHover",
			},
		}}
	>
		{children}
	</Label>
);
