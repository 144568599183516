import type React from "react";

import { type ComponentProps, Label } from "@Components";
import type { IncrementSizes, LabelVariants } from "@Tokens";

export type BadgeNumberSizes = Pick<IncrementSizes, "16" | "24" | "36">;
export interface BadgeNumberProps extends ComponentProps {
	variant: LabelVariants;
	size: keyof BadgeNumberSizes;
}

export const BadgeNumber: React.FC<
	React.PropsWithChildren<BadgeNumberProps>
> = ({ className, "data-id": dataId, children, variant, size }) => (
	<Label
		variant={variant}
		className={className}
		data-id={dataId}
		sx={{
			textAlign: "center",
			display: "flex",
			borderRadius: "50%",
			backgroundColor: "baseSecondary",
			borderColor: "strokePrimarydark",
			color: "strokePrimarydark",
			borderWidth: "outlinedStrokeWeight",
			borderStyle: "solid",
			height: size,
			width: size,
			justifyContent: "center",
			alignItems: "center",
		}}
	>
		{children}
	</Label>
);
