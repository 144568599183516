const range = (start: number, end: number): number[] =>
	Array(Math.max(end - start, 0))
		.fill(0)
		.map((v, i) => i + start);

export const groupSeparatorPageNumber = -1;
const numberOfEndPagesToShow = 1;
const numberOfFirstPage = 0;

const lowerBorderForNumberOfPagesToShow = 4;
export const incorrectMaxNumberMessage = (currentNumber: number) =>
	`Incorrect maxNumberOfPagesToShow=${currentNumber}. Have to be no less than ${lowerBorderForNumberOfPagesToShow}`;

/**
 * Separator (...) have to have more than 1 hidden number,
 * in other case it is useless.
 * Function removeUselessGroupSeparators — just remove useless separators.
 */
const removeUselessGroupSeparators = (finalPages: number[]) =>
	finalPages.map((currentItem, i) => {
		if (
			currentItem === groupSeparatorPageNumber &&
			finalPages[i - 1] + 2 === finalPages[i + 1]
		) {
			return finalPages[i - 1] + 1;
		}

		return currentItem;
	});

export const getVisiblePages = (
	/** start from 0 */
	currentPage: number,
	/** max numbers of pages (example, there are 20 pages, numberOfPages = 20) */
	numberOfPages: number,
	maxNumberOfPagesToShow: number,
): number[] => {
	if (maxNumberOfPagesToShow < lowerBorderForNumberOfPagesToShow) {
		throw new Error(incorrectMaxNumberMessage(maxNumberOfPagesToShow));
	}

	if (numberOfPages > maxNumberOfPagesToShow) {
		/**
		 * Split pagination on three sections: first + center + last.
		 * upperSideLimit is maximum numbers from first and last sections.
		 */
		const upperSideLimit = maxNumberOfPagesToShow - numberOfEndPagesToShow;
		/** currentPage is in first section */
		if (currentPage < upperSideLimit - 1) {
			return removeUselessGroupSeparators([
				...range(numberOfFirstPage, upperSideLimit),
				groupSeparatorPageNumber,
				numberOfPages - 1,
			]);
		}

		/** currentPage is in last section */
		if (numberOfPages - currentPage + 1 <= upperSideLimit) {
			return removeUselessGroupSeparators([
				numberOfFirstPage,
				groupSeparatorPageNumber,
				...range(numberOfPages - upperSideLimit, numberOfPages),
			]);
		}

		/** currentPage is in center section */
		const maxAmountCentralNumbers =
			maxNumberOfPagesToShow - numberOfEndPagesToShow * 2;
		/**
		 * Good to show selected item in the middle of the center section,
		 * for that we need to have odd amount.
		 */
		const oddAmountCentralNumbers =
			maxAmountCentralNumbers % 2 === 1
				? maxAmountCentralNumbers
				: maxAmountCentralNumbers - 1;
		const halfOfCenter = Math.floor(oddAmountCentralNumbers / 2);

		return removeUselessGroupSeparators([
			numberOfFirstPage,
			groupSeparatorPageNumber,
			...range(currentPage - halfOfCenter, currentPage + halfOfCenter + 1),
			groupSeparatorPageNumber,
			numberOfPages - 1,
		]);
	}

	return range(numberOfFirstPage, numberOfPages);
};
