import type React from "react";
import type { SxStyleProp } from "theme-ui";

import { type ComponentProps, Label, TriggerButton } from "@Components";
import type { IncrementSizes, LabelVariants, SpacerSizes } from "@Tokens";

export type ChipSizeVariant = Extract<keyof IncrementSizes, "28" | "36" | "48">;

export interface ChipProps extends ComponentProps {
	size: ChipSizeVariant;
	onClick?: () => void;
}

const sizeToPadding: Record<ChipSizeVariant, keyof SpacerSizes> = {
	48: "s",
	36: "s",
	28: "xs",
};

const sizeToLabelVariant: Record<ChipSizeVariant, LabelVariants> = {
	48: "medium",
	36: "small",
	28: "extrasmall",
};

/**
 * This is a generic component which should not be used directly.
 */
export const Chip: React.FC<React.PropsWithChildren<ChipProps>> = ({
	as: Wrapper = "div",
	onClick,
	trackingAction,
	size,
	"data-id": dataId,
	className,
	children,
	...restProps
}) => {
	const chipStyle: SxStyleProp = {
		position: "relative",
		display: "inline-flex",
		justifyContent: "center",
		alignItems: "center",
		height: size,
		paddingX: sizeToPadding[size],
		borderRadius: "rounded",
		borderWidth: "outlinedStrokeWeight",
		"&:hover": {
			boxShadow: ({ colors }) => `inset 0 0 0 100vh ${colors.overlayHover}`,
		},
	};

	const content =
		typeof children === "string" ? (
			<Label variant={sizeToLabelVariant[size]}>{children}</Label>
		) : (
			children
		);

	return onClick ? (
		<TriggerButton
			trackingAction={trackingAction}
			onTrigger={onClick}
			sx={chipStyle}
			className={className}
			data-id={dataId}
		>
			{content}
		</TriggerButton>
	) : (
		<Wrapper
			sx={chipStyle}
			className={className}
			data-id={dataId}
			{...restProps}
		>
			{content}
		</Wrapper>
	);
};
