import type React from "react";

export const PaginationGroupSeparator: React.FC<
	React.PropsWithChildren<unknown>
> = () => (
	<li
		sx={{
			marginRight: "3xs",
			minWidth: "16px",
			alignItems: "center",
			display: "flex",
		}}
	>
		…
	</li>
);
