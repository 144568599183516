import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";

import { Portal } from "@Components";
import { Toast, type ToastProps } from "./Toast";
import { toAccessibleDuration } from "./duration";

type ToastID = string | number;

interface ToastInstance {
	id: ToastID;
	duration: number;
	Component: React.FC<React.PropsWithChildren<unknown>>;
	timer: NodeJS.Timeout;
}

interface ToastBehaviour {
	/** Duration for the toast to be visible in milliseconds.
	 * Cannot be lower than 3000.
	 * Should be 5000 or higher if using an action.
	 */
	duration?: number;
	/** Passing a static id will enforce only one of these toasts on screen.
	 * Re-triggering the toast will extend the duration. */
	id?: ToastID;
}

export interface ToastInput extends Omit<ToastProps, "id">, ToastBehaviour {}

export interface ToastCustomComponentInput extends ToastBehaviour {
	/**
	 * Component to use as the toast.
	 * It is helpful to use the styles from `ToastContainerStyles` in addition to your own.
	 * Set a background as the component may appear above other page content.
	 */
	Component: React.FC<React.PropsWithChildren<{ onClose: () => void }>>;
}

interface ToastContextProps {
	toast: (props: ToastInput) => void;
	toastCustomComponent: (props: ToastCustomComponentInput) => void;
}

const ToastContextOutOfProviderError = () => {
	throw new Error("Must be a child of ToastProvider");
};

const ToastContext = createContext<ToastContextProps>({
	toast: ToastContextOutOfProviderError,
	toastCustomComponent: ToastContextOutOfProviderError,
});

export const ToastProvider: React.FC<React.PropsWithChildren<unknown>> = ({
	children,
}) => {
	const [toasts, setToasts] = useState<ToastInstance[]>([]);

	const remove = useCallback((id: ToastID) => {
		setToasts((toasts) => {
			const toast = toasts.find((toast) => toast.id === id);

			if (toast) {
				clearTimeout(toast.timer);

				return toasts.filter((toast) => toast.id !== id);
			}

			return toasts;
		});
	}, []);

	const toastCustomComponent: (props: ToastCustomComponentInput) => void =
		useCallback(({ duration: passedDuration, id = Date.now(), Component }) => {
			const duration = toAccessibleDuration(passedDuration, false);

			const createExpirationTimer = () =>
				setTimeout(() => remove(id), duration);

			setToasts((toasts) => {
				const existingToastIndex = toasts.findIndex((toast) => toast.id === id);

				if (existingToastIndex > -1) {
					const clonedToasts = [...toasts];
					// eslint-disable-next-line no-multi-assign
					const toast = (clonedToasts[existingToastIndex] = {
						...toasts[existingToastIndex],
					});

					clearTimeout(toast.timer);
					toast.timer = createExpirationTimer();

					return clonedToasts;
				}

				return toasts.concat({
					id,
					duration,
					timer: createExpirationTimer(),
					Component: React.memo(() => <Component onClose={() => remove(id)} />),
				});
			});
		}, []);

	const toast: (props: ToastInput) => void = useCallback(
		({ duration: passedDuration, id, ...toastProps }: ToastInput) => {
			toastCustomComponent({
				duration: toAccessibleDuration(passedDuration, !!toastProps.actionText),
				id,
				Component: ({ onClose }) => <Toast {...toastProps} onClose={onClose} />,
			});
		},
		[],
	);

	useEffect(
		() => () => {
			setToasts((toasts) => {
				for (const { timer } of toasts) {
					clearInterval(timer);
				}

				return toasts;
			});
		},
		[],
	);

	return (
		<ToastContext.Provider value={{ toast, toastCustomComponent }}>
			{children}
			<Portal>
				<div
					sx={{
						position: "fixed",
						bottom: "l",
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: [null, null, "flex-end"],
						paddingX: ["l", "2xl"],
						gap: "xs",
						zIndex: "toast",
					}}
				>
					{toasts.map(({ id, Component }) => (
						<Component key={id} />
					))}
				</div>
			</Portal>
		</ToastContext.Provider>
	);
};

export const useToast = () => useContext(ToastContext);
