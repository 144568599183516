import type React from "react";

import { type ComponentProps, Label } from "@Components";

interface ContentSwitcherHeaderProps extends ComponentProps {
	isActive?: boolean;
	label: string;
	enableActiveHighlight?: boolean;
}

export const ContentSwitcherHeader = ({
	label,
	isActive = false,
	className,
	enableActiveHighlight = true,
	"data-id": dataId,
}: React.PropsWithChildren<ContentSwitcherHeaderProps>) => (
	<Label
		className={className}
		data-id={dataId}
		variant="small"
		sx={{
			whiteSpace: "nowrap",
			display: "block",
			borderRadius: "rounded",
			paddingY: "3xs",
			paddingX: "s",
			paddingTop: "3xs",
			paddingBottom: "3xs",
			...(enableActiveHighlight &&
				!isActive && {
					"&:hover": {
						backgroundColor: "backgroundLightneutral",
					},
				}),
			...(enableActiveHighlight &&
				isActive && {
					backgroundColor: "tagprimaryDefault",
				}),
		}}
	>
		{label}
	</Label>
);
