import type React from "react";

import { type ComponentProps, Label } from "@Components";
import type { LabelVariants } from "@Tokens";
import type { Responsive } from "@Utils";

export interface TabHeaderProps extends ComponentProps {
	isActive?: boolean;
	labelVariant?: Responsive<LabelVariants>;
	label: string;
}

export const TabHeader: React.FC<React.PropsWithChildren<TabHeaderProps>> = ({
	isActive = false,
	labelVariant = "small",
	label,
	className,
}) => (
	<Label
		className={className}
		variant={labelVariant}
		sx={{
			display: "block",
			paddingBottom: "2xs",
			borderBottomWidth: "activeStrokeWeight",
			borderStyle: isActive ? "solid" : null,
			color: isActive ? "textDefault" : "textDimmedmedium",
			"&:hover": {
				color: isActive ? null : "textDisabled",
				borderStyle: "solid",
			},
		}}
	>
		{label}
	</Label>
);
