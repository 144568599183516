import type React from "react";
import { Fragment } from "react";
import type { SxStyleProp } from "theme-ui";

import { type ComponentProps, Icon, type IconProps, Label } from "@Components";
import type {
	BadgeSize,
	BadgeVariant,
	IncrementSizes,
	LabelVariants,
	SpacerSizes,
} from "@Tokens";
import { type Responsive, createResponsiveConfig } from "@Utils";

const badgeInformativeVariantStyles: Record<BadgeVariant, SxStyleProp> = {
	Neutral: {
		backgroundColor: "backgroundLightsubtle",
	},
	NeutralInverted: {
		backgroundColor: "backgroundDark",
		color: "textLight",
	},
	Informative: {
		backgroundColor: "backgroundInformationlight",
	},
	InformativeInverted: {
		backgroundColor: "backgroundInformationdark",
		color: "textLight",
	},
	Success: {
		backgroundColor: "backgroundSuccesslight",
	},
	SuccessInverted: {
		backgroundColor: "backgroundSuccessdark",
		color: "textLight",
	},
	Warning: {
		backgroundColor: "backgroundWarningmedium",
	},
	WarningInverted: {
		backgroundColor: "backgroundWarningdark",
		color: "textLight",
	},
	Critical: {
		backgroundColor: "backgroundCriticallight",
	},
	CriticalInverted: {
		backgroundColor: "backgroundCriticaldark",
		color: "textLight",
	},
	PrimaryYellow: {
		backgroundColor: "baseYellowlight",
	},
	PrimaryYellowInverted: {
		backgroundColor: "baseYellow",
	},
	PrimaryBlue: {
		backgroundColor: "baseSecondary",
	},
	PrimaryBlueInverted: {
		backgroundColor: "basePrimary",
		color: "textLight",
	},
	NeutralTransparent: {
		backgroundColor: "backgroundNone",
	},
	NeutralInvertedTransparent: {
		backgroundColor: "backgroundNone",
		color: "textLight",
	},
	Tag: {
		backgroundColor: "tagprimaryDefault",
		color: "textLight",
	},
};

export interface BadgeInformativeProps
	extends ComponentProps,
		BadgeInformativeInteractionProps {
	variant: BadgeVariant;
	size: Responsive<BadgeSize>;
	icon?: IconProps["name"];
	iconPosition?: "left" | "right";
}

type BadgeInformativeConfig = Record<
	BadgeSize,
	{
		labelVariant: LabelVariants;
		paddingY: keyof SpacerSizes;
		iconSize: keyof IncrementSizes;
	}
>;

const badgeInformativeConfig = createResponsiveConfig<BadgeInformativeConfig>({
	24: {
		labelVariant: "small",
		paddingY: "4xs",
		iconSize: "16",
	},
});

export interface BadgeInformativeInteractionProps {
	interactionIconAlt?: IconProps["name"];
	interactionStyleOverride?: SxStyleProp;
}

export const BadgeInformative: React.FC<
	React.PropsWithChildren<BadgeInformativeProps>
> = ({
	className,
	"data-id": dataId,
	children,
	variant,
	size,
	icon,
	iconPosition,
	interactionIconAlt,
	interactionStyleOverride,
}) => {
	const { labelVariant, paddingY, iconSize } = badgeInformativeConfig.get(size);
	const hasHoverIcon = !!interactionIconAlt;
	const toggleIcon = (
		<Fragment>
			<Icon size={iconSize} name={icon} />
			{hasHoverIcon && <Icon size={iconSize} name={interactionIconAlt} />}
		</Fragment>
	);

	return (
		<Label
			className={className}
			data-id={dataId}
			variant={labelVariant}
			sx={{
				whiteSpace: "nowrap",
				paddingX: "3xs",
				paddingY,
				borderRadius: "4",
				display: "inline-flex",
				alignItems: "center",
				gap: "4xs",
				...badgeInformativeVariantStyles[variant],
				":hover": {
					...(interactionStyleOverride ?? {}),
				},

				...(hasHoverIcon && {
					"svg:nth-of-type(2)": { display: "none" },
					":hover": {
						"svg:nth-of-type(1)": { display: "none" },
						"svg:nth-of-type(2)": { display: "block" },
						...(interactionStyleOverride ?? {}),
					},
				}),
			}}
		>
			{iconPosition === "left" && icon && toggleIcon}
			{children}
			{iconPosition === "right" && icon && toggleIcon}
		</Label>
	);
};
