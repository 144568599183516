import type React from "react";

import type { ComponentProps } from "@Components";
import type { ThemeDefinition } from "@Themes";
import type { HeadingVariants } from "@Tokens";
import type { Responsive } from "@Utils";

export interface HeadingProps extends ComponentProps {
	as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

	/**
	 * The Heading variant.
	 */
	variant: Responsive<HeadingVariants>;
}

export const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
	as: Wrapper,
	variant,
	...props
}) => (
	<Wrapper
		sx={(theme: ThemeDefinition) =>
			Array.isArray(variant)
				? { "&": variant.map((v) => theme.typography.heading[v]) }
				: theme.typography.heading[variant]
		}
		{...props}
	/>
);
