import type React from "react";
import {
	type ElementType,
	type PropsWithChildren,
	useRef,
	useState,
} from "react";

import { useLayoutEffect } from "@Hooks";

const isServer = typeof window === "undefined";

type DontHydrateProps = PropsWithChildren<
	Record<string, unknown> & { as?: ElementType }
>;

export const DontHydrate: React.FC<DontHydrateProps> = ({
	children,
	as = "div",
	...props
}) => {
	const ref = useRef<HTMLElement>(null);
	const [render, setRender] = useState(isServer);

	useLayoutEffect(() => {
		// check if the innerHTML is empty as client side navigation
		// need to render the component without server-side backup
		const isEmpty = ref?.current?.innerHTML === "";
		if (isEmpty) {
			setRender(true);
		}
	}, []);

	const Component = as;

	// if we're in the server or a spa navigation, just render it
	if (render) {
		return <Component {...props}>{children}</Component>;
	}

	// avoid re-render on the client
	return (
		<Component
			{...props}
			ref={ref}
			suppressHydrationWarning
			dangerouslySetInnerHTML={{ __html: "" }}
		/>
	);
};
