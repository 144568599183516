import {
	type Placement,
	type ReferenceType,
	type Side,
	arrow,
	flip,
	offset,
	safePolygon,
	shift,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
} from "@floating-ui/react";
import type React from "react";
import { type ReactNode, useRef, useState } from "react";

import {
	Paragraph,
	Portal,
	type TriggerButtonBehaviourProps,
} from "@Components";
import { useId } from "@Hooks";

export interface TooltipProps {
	/** The content displayed in the tooltip itself */
	content: ReactNode;
	/** Only used for demonstration in Storybook */
	alwaysOpened?: boolean;
	disableTouch?: boolean;

	/* text to assist screenreader, necessary when the tooltip-trigger doesn't have a text */
	altText?: string;

	delay?: number;
}

export interface TooltipReturn {
	/** Spread into the button that triggers the tooltip */
	triggerProps: {
		"aria-describedby": string;
		ref: (node: ReferenceType) => void;
	} & TriggerButtonBehaviourProps;
	triggerRef: React.MutableRefObject<HTMLElement>;
	/** Render this as part of your component. It's a portal so won't disrupt your layout */
	portal: React.ReactNode;
}

export const TOOLTIP_SPACING_FROM_SCREEN_EDGE = 12;
const SPACING_BETWEEN_TRIGGER_AND_TOOLTIP = 12;
const ARROW_SIZE = 10;

const reversePositions: Record<Side, Side> = {
	top: "bottom",
	right: "left",
	bottom: "top",
	left: "right",
};

const getSideFromPlacement: (placement: Placement) => Side = (placement) =>
	reversePositions[placement.split("-")[0] as Side];

export const useTooltip: (props: TooltipProps) => TooltipReturn = ({
	content,
	alwaysOpened = false,
	disableTouch = false,
	delay = 25,
}) => {
	const arrowRef = useRef(null);

	const [isOpened, setIsOpened] = useState(alwaysOpened);
	const onOpenChange = (open: boolean) => setIsOpened(alwaysOpened || open);
	const {
		x,
		y,
		reference: setTriggerRef,
		placement,
		floating: tooltipRef,
		strategy,
		refs: { reference: triggerRef },
		middlewareData: {
			arrow: { x: arrowX, y: arrowY } = {},
		},
		context,
	} = useFloating({
		placement: "top",
		open: isOpened,
		onOpenChange,
		middleware: [
			offset(SPACING_BETWEEN_TRIGGER_AND_TOOLTIP),
			flip(),
			shift({ padding: TOOLTIP_SPACING_FROM_SCREEN_EDGE }),
			arrow({ element: arrowRef }),
		],
	});

	const staticSide = getSideFromPlacement(placement);

	const focus = useFocus(context);

	const id = useId();
	const { getReferenceProps } = useInteractions([
		useHover(context, {
			handleClose: safePolygon({
				blockPointerEvents: false,
			}),
			delay,
			mouseOnly: disableTouch,
		}),
		useDismiss(context),
		focus,
	]);

	return {
		triggerProps: {
			ref: setTriggerRef,
			"aria-describedby": id,
			...getReferenceProps(),
		},
		triggerRef: triggerRef as React.MutableRefObject<HTMLElement>,

		portal: isOpened && (
			<Portal>
				<div
					id={id}
					ref={tooltipRef}
					sx={{
						position: strategy,
						backgroundColor: "backgroundBlack",
						color: "white",
						padding: "2xs",
						borderRadius: "4",
						width: "max-content",
						maxWidth: ["80vw", "400px"],
						zIndex: "tooltip",
						top: y,
						left: x,
					}}
				>
					<div
						aria-hidden="true"
						ref={arrowRef}
						sx={{
							position: "absolute",
							transform: "rotate(45deg)",
							width: ARROW_SIZE,
							height: ARROW_SIZE,
							backgroundColor: "backgroundBlack",
							left: arrowX,
							top: arrowY,
							[staticSide]: -ARROW_SIZE / 2,
						}}
					/>
					{typeof content === "string" ? (
						<Paragraph variant="extrasmall">{content}</Paragraph>
					) : (
						content
					)}
				</div>
			</Portal>
		),
	};
};
