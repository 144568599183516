import React from "react";

import type { ComponentProps } from "@Components";
import type { ColorsPalette } from "@Themes";
import { type Icons, type IncrementSizes, iconsFilename } from "@Tokens";
import type { Responsive } from "@Utils";

const display = (index: number, total: number, breakpoint: number) => {
	// Show icons when it is their breakpoint
	// Mobile is shown by default, so don't return for it.
	if (index === breakpoint && index !== 0) {
		return "block";
	}

	// Hide icons before they're needed. We do this once on mobile.
	// Hide icons in the breakpoint after they're used - unless that was the last icon.
	if (
		(breakpoint === 0 && index !== 0) ||
		(index + 1 === breakpoint && index + 1 !== total)
	) {
		return "none";
	}

	return null;
};

export interface IconProps extends ComponentProps {
	/**
	 * The name of the icon.
	 */
	name: Icons | Responsive<Icons>;

	/**
	 * The size of the icon. Use increments size token here.
	 */
	size: keyof IncrementSizes | (keyof IncrementSizes)[];

	/**
	 * The color of the icon. Use colour token here.
	 */
	color?: keyof ColorsPalette;
}

/**
 * Component to render icons.
 */
export const Icon = React.forwardRef<SVGSVGElement, IconProps>(
	({ name, size, color, className, "data-id": dataId }, ref) => {
		const names = Array.isArray(name) ? name : [name];
		const symbols = names.map((name) => name.toLowerCase().replace("/", "-"));

		return (
			<svg
				ref={ref}
				className={className}
				data-id={dataId}
				sx={{
					display: "inline-flex",
					alignItems: "center",
					justifyContent: "center",
					fill: color,
					height: size,
					width: size,
					minWidth: size,
					minHeight: size,
				}}
			>
				{symbols.map((symbol, index) => (
					<use
						href={`/${iconsFilename}#${symbol}`}
						key={index}
						sx={{
							display: [
								display(index, names.length, 0),
								display(index, names.length, 1),
								display(index, names.length, 2),
							],
						}}
					/>
				))}
			</svg>
		);
	},
);
