import type React from "react";
import { type ChangeEvent, Fragment, type ReactNode } from "react";
import type { SxStyleProp } from "theme-ui";

import { type ComponentProps, Icon, Label } from "@Components";
import type {
	Icons,
	IncrementSizes,
	LabelVariants,
	SelectorVariant,
	SpacerSizes,
} from "@Tokens";
import { negativeBorder } from "@Utils";
import { Chip, type ChipProps, type ChipSizeVariant } from "../Chip";

export const variantStyles: Record<SelectorVariant, SxStyleProp> = {
	Primary: {
		backgroundColor: "backgroundPrimarylight",
		color: "textDimmedheavy",
		borderColor: "backgroundPrimarylight",
		borderStyle: "solid",
		borderWidth: "outlinedStrokeWeight",
	},
	Light: {
		backgroundColor: "backgroundLight",
		color: "textDimmedheavy",
		borderColor: "strokeDarkneutral",
		borderStyle: "solid",
		borderWidth: "outlinedStrokeWeight",
	},
	Heavy: {
		backgroundColor: "backgroundDark",
		color: "iconLight",
		borderColor: "backgroundDark",
		borderStyle: "solid",
		borderWidth: "outlinedStrokeWeight",
	},
};

export const sizeToLabelVariant: Record<ChipSizeVariant, LabelVariants> = {
	48: "medium",
	36: "small",
	28: "extrasmall",
};

export const sizeToIconSize: Record<ChipSizeVariant, keyof IncrementSizes> = {
	48: "16",
	36: "16",
	28: "12",
};

const sizeToPadding: Record<ChipSizeVariant, keyof SpacerSizes> = {
	48: "xs",
	36: "2xs",
	28: "3xs",
};

const sizeToMargin: Record<ChipSizeVariant, keyof SpacerSizes> = {
	48: "3xs",
	36: "3xs",
	28: "4xs",
};

const countVariantStyle: Record<SelectorVariant, SxStyleProp> = {
	Primary: {
		backgroundColor: "backgroundLight",
		color: "textDefault",
	},
	Light: {
		backgroundColor: "backgroundBlack",
		color: "textLight",
	},
	Heavy: {
		backgroundColor: "backgroundLight",
		color: "textDefault",
	},
};

const countStyles = (
	size: ChipSizeVariant,
	variant: SelectorVariant,
): SxStyleProp => ({
	borderRadius: "rounded",
	height: "20",
	width: "20",
	textAlign: "center",
	...(size !== "28" && countVariantStyle[variant]),
	lineHeight: ({ sizes }) => `${String(sizes["20"])}px`,
});

const selectedLightVariantStyle: SxStyleProp = {
	color: "textDefault",
	borderColor: "strokeDark",
	outlineWidth: ({ borderWidths }) => borderWidths.activeStrokeWeight,
	outlineOffset: negativeBorder("activeStrokeWeight"),
	outlineStyle: "solid",
};

export interface CommonProps extends ComponentProps {
	/**
	 * The color variant
	 */
	variant: SelectorVariant;
	/**
	 * The size variant
	 */
	size: ChipSizeVariant;
	/**
	 * If true it will have a selectedStyle
	 */
	isSelected?: boolean;
}

export interface NormalSelectorProps {
	onClick?: ChipProps["onClick"];
	icon: Icons;
	label: string;
	/**
	 * count state
	 */
	count?: number;
	/**
	 * If true it will have a selectedStyle
	 */
	isSelected: boolean;
}

export interface DropdownSelectorProps {
	children: ReactNode;
	value: string | number;
	onChange(event: ChangeEvent<HTMLSelectElement>): void;
}

export type SelectorProps = CommonProps &
	(NormalSelectorProps | DropdownSelectorProps);

export const Selector: React.FC<React.PropsWithChildren<SelectorProps>> = ({
	"data-id": dataId,
	as,
	size,
	variant,
	className,
	isSelected,
	children,
	...restProps
}) => {
	const { onClick, label, count, icon } = restProps as NormalSelectorProps;

	return (
		<Chip
			data-id={dataId}
			className={className}
			as={as}
			size={size}
			sx={{
				paddingX: sizeToPadding[size],
				...variantStyles[variant],
				...(isSelected &&
					variant === "Light" && {
						...selectedLightVariantStyle,
					}),
			}}
			onClick={onClick}
			{...(children && { ...restProps })}
		>
			{children || (
				<Fragment>
					<Label
						variant={sizeToLabelVariant[size]}
						sx={{
							marginX: sizeToMargin[size],
						}}
					>
						{label}
					</Label>

					{count && (
						<Label
							variant="extrasmallbold"
							sx={{
								...countStyles(size, variant),
							}}
						>
							{count}
						</Label>
					)}

					{!count && <Icon name={icon} size={sizeToIconSize[size]} />}
				</Fragment>
			)}
		</Chip>
	);
};
