import type React from "react";
import type { ChangeEvent } from "react";
import type { SxStyleProp } from "theme-ui";

import { type ComponentProps, Icon } from "@Components";
import type { Icons } from "@Tokens";
import {
	Selector,
	type SelectorProps,
	sizeToIconSize,
	sizeToLabelVariant,
	variantStyles,
} from "./Selector";

interface SelectOption {
	disabled?: boolean;
	label: string;
	value: string | number;
}

export type DropdownSelectorProps = Pick<SelectorProps, "size" | "variant"> &
	ComponentProps & {
		options: SelectOption[];
		value: string | number;
		onChange(event: ChangeEvent<HTMLSelectElement>): void;
		selectStyles?: SxStyleProp;
		ariaLabel?: string;
		icon?: Icons;
	};

export const DropdownSelector: React.FC<
	React.PropsWithChildren<DropdownSelectorProps>
> = ({
	"data-id": dataId,
	size,
	variant,
	options,
	value,
	onChange,
	className,
	ariaLabel,
	selectStyles: selectorStyles,
	icon = "Actions/Select",
}) => (
	<div
		className={className}
		sx={{
			display: "inline-block",
			position: "relative",
		}}
	>
		<Selector
			aria-label={ariaLabel}
			data-id={dataId}
			value={value}
			onChange={onChange}
			variant={variant}
			as="select"
			size={size}
			sx={{
				appearance: "none",
				paddingRight: "xl",
				fontSize: ({ typography }) =>
					typography.label[sizeToLabelVariant[size]].fontSize,
				...selectorStyles,
			}}
		>
			{options.map(({ disabled, label, value }) => (
				<option key={label} disabled={disabled} value={value}>
					{label}
				</option>
			))}
		</Selector>
		<Icon
			name={icon}
			size={sizeToIconSize[size]}
			sx={{
				zIndex: "inputDropdown",
				pointerEvents: "none",
				top: "50%",
				transform: "translateY(-50%)",
				position: "absolute",
				right: "xs",
				verticalAlign: "middle",
				color: (variantStyles[variant] as any).color,
			}}
		/>
	</div>
);
