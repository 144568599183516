import type React from "react";

import { useTranslation } from "@Providers/TranslationProvider";
import { Button } from "../Button";

export enum Direction {
	LEFT = -1,
	RIGHT = 1,
}

interface PaginationChevronButtonProps {
	direction: Direction;
	enabled: boolean;
	onClick: (diff: number) => void;
}

type PaginationChevronButtonType = React.FC<
	React.PropsWithChildren<PaginationChevronButtonProps>
>;

export const PaginationChevronButton: PaginationChevronButtonType = ({
	direction,
	enabled,
	onClick,
}) => {
	const { t } = useTranslation();

	return (
		<li
			sx={{
				marginRight: "2xs",
				marginLeft: direction === Direction.RIGHT ? "4xs" : null,
				minWidth: "36px",
				alignItems: "center",
				display: "flex",
				position: "relative",
				paddingX: "5xs",
			}}
		>
			<Button
				icon={
					direction === Direction.RIGHT
						? "Actions/MoveNext"
						: "Actions/MovePrevious"
				}
				size="36"
				variant="Outline"
				disabled={!enabled}
				onClick={() => enabled && onClick(direction)}
				ariaLabel={direction === Direction.LEFT ? t("previous") : t("next")}
			/>
		</li>
	);
};
