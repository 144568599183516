import React from "react";

import { type ComponentProps, TriggerButton } from "@Components";
import { useTabsContext } from "./TabsContext";
import { generatePanelId } from "./generatePanelId";
import { generateTabId } from "./generateTabId";

export interface TabProps extends ComponentProps {
	id: string;
	header: React.ReactElement;
	disabled?: boolean;
	// eslint-disable-next-line react/no-unused-prop-types
	lazy?: boolean;
}

export const Tab: React.FC<React.PropsWithChildren<TabProps>> = ({
	id,
	className,
	header,
	disabled,
}) => {
	const { activeTabId, changeTab, prefix } = useTabsContext();

	const isActive = activeTabId === id;

	const headerWithIsActive = React.cloneElement(header, {
		isActive,
	});

	return (
		<TriggerButton
			trackingAction={`${prefix}-tab`}
			eventLabel={id}
			role="tab"
			data-id={id}
			aria-selected={isActive}
			aria-controls={generatePanelId(prefix, id)}
			tabIndex={isActive ? 0 : -1}
			id={generateTabId(prefix, id)}
			onTrigger={() => changeTab(id)}
			className={className}
			disabled={disabled}
			sx={{
				...(disabled && { opacity: "60%" }),
			}}
		>
			{headerWithIsActive}
		</TriggerButton>
	);
};
