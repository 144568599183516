import React, {
	forwardRef,
	type InputHTMLAttributes,
	useEffect,
	useMemo,
	useState,
} from "react";

import { useId } from "@Hooks";
import { useTranslation } from "@Providers/TranslationProvider";
import type { IncrementSizes } from "@Tokens";
import {
	InputFieldDecoration,
	type InputFieldDecorationProps,
	inputFieldStyles,
} from "./InputFieldDecoration";

export interface InputFieldPasswordProps
	extends Pick<
			InputFieldDecorationProps,
			"label" | "message" | "value" | "state" | "data-id"
		>,
		Omit<
			InputHTMLAttributes<HTMLInputElement>,
			"className" | "sx" | "id" | "value" | "size" | "type" | "disabled"
		> {
	size: keyof Pick<IncrementSizes, "64" | "48">;
}

export const InputFieldPassword = forwardRef<
	HTMLInputElement,
	InputFieldPasswordProps
>(({ label, message, size, state, value, "data-id": dataId, ...rest }, ref) => {
	const id = useId();

	const [reveal, setReveal] = useState(false);

	const { t } = useTranslation();

	useEffect(() => {
		if (!value) {
			setReveal(false);
		}
	}, [value, setReveal]);

	const button: InputFieldDecorationProps["button"] = useMemo(
		() =>
			value
				? {
						icon: reveal ? "Toggles/ShowOff" : "Toggles/ShowOn",
						onClick: () => setReveal((v) => !v),
					}
				: undefined,
		[!!value, reveal, setReveal],
	);

	return (
		<InputFieldDecoration
			button={button}
			id={id}
			label={label}
			message={message}
			size={size}
			value={value}
			state={state}
			data-id={dataId}
			onButtonAndInputContainerBlur={(e) => {
				if (!e.currentTarget.contains(e.relatedTarget)) {
					setReveal(false);
				}
			}}
		>
			<input
				ref={ref}
				placeholder={t("password")}
				type={reveal ? "text" : "password"}
				value={value}
				disabled={state === "Disabled"}
				id={id}
				sx={inputFieldStyles({ button, state })}
				{...rest}
			/>
		</InputFieldDecoration>
	);
});
