import React, { createElement, type ReactNode, Fragment } from "react";
import RehypeReact from "rehype-react";

import { Link, List, Paragraph } from "@Components";
import type { HastComponentConfig, HastNode } from "./types";

export const hastToReact = (
	hastNode?: HastNode,
	components: HastComponentConfig = {},
): ReactNode =>
	hastNode
		? new RehypeReact({
				createElement,
				Fragment,
				components: {
					p: Paragraph,
					a: Link,
					ul: List,
					...Object.keys(components).reduce((prev, key) => {
						const { Component, Props = {} } = components[key];

						return {
							...prev,
							[key]: (props: any): ReactNode => (
								<Component {...props} {...Props} />
							),
						};
					}, {}),
				},
			}).Compiler(hastNode)
		: "";
