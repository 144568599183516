import type React from "react";
import type { ReactElement, ReactNode } from "react";
import type { SxStyleProp } from "theme-ui";

import { type ComponentProps, Heading, Icon, Paragraph } from "@Components";
import type { Color, Icons } from "@Tokens";

type AlertVariant = "Warning" | "Critical" | "Success" | "Informative";

export interface AlertProps extends ComponentProps {
	headline: string;
	variant: AlertVariant;
	hideIcon?: boolean;
	actions?: ReactNode | ((variant: AlertVariant) => ReactElement<any>);
	customIconName?: Icons;
	customIconColor?: Color;
	filled?: boolean;
}

interface VariantOption {
	icon: Icons;
	containerStyle: SxStyleProp;
	iconStyle: SxStyleProp;
}

const variantsOptions = ({
	filled = true,
	variant,
}: {
	filled?: boolean;
	variant: AlertVariant;
}): VariantOption => {
	const variantStyles: Record<AlertVariant, VariantOption> = {
		Warning: {
			icon: "Markers/SafetyAlert",
			containerStyle: {
				backgroundColor: filled ? "backgroundWarningmedium" : "backgroundWhite",
				border: (t) =>
					`1px solid ${filled ? t.colors.strokeWarninglarge : t.colors.strokeLightsubtle}`,
			},
			iconStyle: {
				color: "iconWarningdark",
			},
		},
		Critical: {
			icon: "Markers/Alert",
			containerStyle: {
				backgroundColor: filled ? "backgroundCriticallight" : "backgroundWhite",
				border: (t) =>
					`1px solid ${filled ? t.colors.strokeCriticalmedium : t.colors.strokeLightsubtle}`,
			},
			iconStyle: {
				color: "iconCriticaldark",
			},
		},
		Success: {
			icon: "Markers/Confirmation",
			containerStyle: {
				backgroundColor: filled ? "backgroundSuccesslight" : "backgroundWhite",
				border: (t) =>
					`1px solid ${filled ? t.colors.strokeSuccessmedium : t.colors.strokeLightsubtle}`,
			},
			iconStyle: {
				color: "iconSuccessdark",
			},
		},
		Informative: {
			icon: "Markers/Information",
			containerStyle: {
				backgroundColor: filled
					? "backgroundInformationlight"
					: "backgroundWhite",
				border: (t) =>
					`1px solid ${filled ? t.colors.strokeInformationmedium : t.colors.strokeLightsubtle}`,
			},
			iconStyle: {
				color: "iconInformationdark",
			},
		},
	};

	return variantStyles[variant];
};

function isRenderProp(
	children: AlertProps["actions"],
): children is (variant: AlertVariant) => JSX.Element {
	return typeof children === "function";
}

export const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({
	variant,
	headline,
	className,
	hideIcon,
	"data-id": dataId,
	children,
	actions,
	customIconName,
	customIconColor,
	filled = true,
}) => (
	<div
		className={className}
		sx={{
			borderRadius: "12",
			padding: "l",
			display: "flex",
			...variantsOptions({ variant, filled }).containerStyle,
		}}
		data-id={dataId}
	>
		{!hideIcon && (
			<Icon
				name={customIconName || variantsOptions({ variant, filled }).icon}
				size="24"
				sx={{
					marginRight: "2xs",
					...variantsOptions({ variant, filled }).iconStyle,
					...(customIconColor && { color: customIconColor }),
				}}
			/>
		)}
		<div sx={{ alignSelf: "flex-start" }}>
			<Heading variant="smallbold" as="h3">
				{headline}
			</Heading>
			{!!children && (
				<Paragraph variant="extrasmall" sx={{ marginTop: "3xs" }}>
					{children}
				</Paragraph>
			)}
			{!!actions && (
				<div sx={{ marginTop: "xs", "& > *": { marginRight: "2xs" } }}>
					{isRenderProp(actions) ? actions(variant) : actions}
				</div>
			)}
		</div>
	</div>
);
