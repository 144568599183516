import { type TrackingObject, useTrackingContext } from "@Components";
import { useInView } from "./useInView";

export const useInViewTracking = (
	eventData: TrackingObject & {
		event: "Product List Viewed" | "Product Viewed";
	},
	disabled?: boolean,
) => {
	const { sendEvent } = useTrackingContext();
	const ref = useInView(
		(isInView) => {
			if (isInView) {
				sendEvent(eventData);
			}
		},
		{
			triggerOnlyOnce: true,
			threshold: 0.45,
			disabled,
		},
	);

	return ref;
};
