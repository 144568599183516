import React, { type ReactElement, type ReactNode } from "react";

import { ControlledTabs } from "./ControlledTabs";
import { Tab, type TabProps } from "./Tab";
import { UncontrolledTabs } from "./UncontrolledTabs";
import type { TabsProps } from "./types";

type TabsType = React.FC<React.PropsWithChildren<TabsProps>> & {
	Tab: React.FC<React.PropsWithChildren<TabProps>>;
};

export const Tabs: TabsType = (props) => {
	const { children, value } = props;

	const tabs = React.Children.toArray(children).filter(Boolean) as ReactElement<
		TabProps & { children: ReactNode }
	>[];

	const isControlled = value !== undefined;

	return isControlled ? (
		<ControlledTabs activeTabId={value} tabs={tabs} {...props} />
	) : (
		<UncontrolledTabs tabs={tabs} {...props} />
	);
};

Tabs.Tab = Tab;
