import type React from "react";

import type { ComponentProps } from "@Components";
import type { ThemeDefinition } from "@Themes";
import type { ParagraphVariants } from "@Tokens";

export interface ParagraphProps extends Omit<ComponentProps, "as"> {
	/**
	 * The Paragraph variant.
	 */
	variant: ParagraphVariants;
}

export const Paragraph: React.FC<React.PropsWithChildren<ParagraphProps>> = ({
	variant,
	...props
}) => (
	<p
		sx={(theme: ThemeDefinition) => theme.typography.paragraph[variant]}
		{...props}
	/>
);
