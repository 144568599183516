import React, { Fragment, forwardRef } from "react";
import type { SxStyleProp } from "theme-ui";

import {
	Clickable,
	type ClickableProps,
	Icon,
	type IconProps,
	Label,
	type TriggerEvent,
} from "@Components";
import { spin } from "@Keyframes";
import { useTranslation } from "@Providers/TranslationProvider";
import {
	type Icons,
	type NewButtonSize,
	type NewButtonType,
	buttonVariantStyles,
} from "@Tokens";

const extraStylesForOutlineVariant = (disabled: boolean): SxStyleProp => ({
	boxShadow: (t) =>
		`0 0 0 1px ${disabled ? t.colors.strokeDisabledlight : t.colors.strokeDark} inset`,
});

const getVariantStyles = (
	variant: NewButtonType,
	disabled: boolean,
	paddingStyles: SxStyleProp,
	isIconButton: boolean,
): SxStyleProp => {
	const variantConfig = buttonVariantStyles[variant];

	return {
		...variantConfig.Default,
		...paddingStyles,
		...(isIconButton && { borderRadius: "rounded" }),
		...(variant === "Outline" && extraStylesForOutlineVariant(disabled)),
		"&:hover": {
			...variantConfig.Hover,
			...(isIconButton && { borderRadius: "rounded" }),
		},
		...(!disabled && {
			"&:active": {
				...variantConfig.Pressed,
				...(isIconButton && { borderRadius: "rounded" }),
			},
		}),
		...((disabled && {
			...variantConfig.Disabled,
			...(isIconButton && { borderRadius: "rounded" }),
			"&:hover": {
				...variantConfig.Disabled,
				...(isIconButton && { borderRadius: "rounded" }),
			},
		}) as SxStyleProp),
	} as SxStyleProp;
};

const getPadding = (size: NewButtonSize, isRound: boolean): SxStyleProp => {
	// The 2px adjustment below is needed because the construction in Figma does not use the right
	// tokens (nor we have the right ones). Adding the adjustment here while we wait for a DS solution
	const styleMap = {
		64: {
			paddingX: ({ space }) => (isRound ? space.s + 2 : space.l),
			paddingY: ({ space }) => (isRound ? space.s + 2 : space.s),
		} as SxStyleProp,
		48: {
			paddingX: ({ space }) => (isRound ? space["2xs"] + 2 : space.l),
			paddingY: ({ space }) => (isRound ? space["2xs"] + 2 : space["2xs"]),
		} as SxStyleProp,
		36: {
			paddingX: ({ space }) => (isRound ? space["3xs"] + 2 : space.l),
			paddingY: ({ space }) => (isRound ? space["3xs"] + 2 : space["3xs"]),
		} as SxStyleProp,
	};

	return styleMap[size] as SxStyleProp;
};

const sizeToIconSize: Record<NewButtonSize, IconProps["size"]> = {
	64: "20",
	48: "20",
	36: "16",
};

export interface ButtonProps extends ClickableProps {
	/**
	 * The size variant
	 */
	size: NewButtonSize;
	/**
	 * the button variant
	 */
	variant: NewButtonType;
	/**
	 * name of icon
	 */
	icon?: Icons;

	/**
	 * Whether the button should take the minimum space or extend to 100%
	 */
	stretch?: boolean;

	ariaLabel?: string;

	onClick?: (e: TriggerEvent) => void;
	loading?: boolean;
	disabled?: boolean;
}

export const Button = forwardRef<any, ButtonProps>(
	(
		{
			as = "div",
			size,
			stretch = false,
			children,
			onClick = () => {},
			className,
			trackingAction,
			eventLabel,
			href,
			internal,
			icon,
			"data-id": dataId,
			ariaLabel,
			disabled = false,
			loading = false,
			target,
			rel,
			variant,
			asAnchor,
			buttonType,
			name,
			value,
		},
		ref,
	) => {
		const { t } = useTranslation();

		// Only renders a round button if there is a icon and no children
		const isIconButton = !children && !!icon;

		const buttonStyle: SxStyleProp = {
			userSelect: "none",
			display: "inline-flex",
			alignItems: "center",
			justifyContent: "center",
			...(stretch && !isIconButton && { width: "100%" }),
			textAlign: "center",
			position: "relative",
		};

		const text =
			typeof children === "string" ? (
				<Label variant={size === "36" ? "smallbutton" : "largebutton"}>
					{children}
				</Label>
			) : (
				children
			);

		const textWithIcon = (
			<Fragment>
				{!!icon && (
					<Icon
						// TODO: Update with size variants from DS
						size={sizeToIconSize[size]}
						name={icon}
						sx={{
							marginRight: isIconButton ? 0 : "3xs",
						}}
					/>
				)}
				{!isIconButton && text}
			</Fragment>
		);

		const buttonContent = loading ? (
			<Fragment>
				<span
					sx={{
						visibility: "hidden",
						display: "flex",
					}}
				>
					{textWithIcon}
				</span>
				<Icon
					size="20"
					name="Actions/Spinner"
					sx={{
						animation: `${spin} 1.5s linear infinite`,
						position: "absolute",
					}}
				/>
			</Fragment>
		) : (
			textWithIcon
		);

		const variantStyles = getVariantStyles(
			variant,
			disabled,
			getPadding(size, isIconButton),
			isIconButton,
		);

		return (
			<Clickable
				ref={ref}
				as={as}
				asAnchor={asAnchor}
				disabled={disabled}
				ariaLabel={loading ? t("loading") : ariaLabel}
				internal={internal}
				target={target}
				rel={rel}
				className={className}
				data-id={dataId}
				href={href}
				onClick={onClick}
				linkStyles={{
					textDecoration: "none",
					...buttonStyle,
					...variantStyles,
					"&:visited": {
						...variantStyles,
					},
				}}
				buttonStyles={{
					...buttonStyle,
					...variantStyles,
				}}
				eventLabel={eventLabel}
				trackingAction={trackingAction}
				buttonType={buttonType}
				name={name}
				value={value}
			>
				{buttonContent}
			</Clickable>
		);
	},
);
