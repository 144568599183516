import React from "react";
import type { SxStyleProp } from "theme-ui";

import { type ComponentProps, Icon, type IconProps, Label } from "@Components";

export const badgeBookingStateCategories = [
	"Confirmed",
	"Processing",
	"Cancelled",
	"Travelled",
	"ConfirmedDark",
	"ProcessingDark",
	"CancelledDark",
	"TravelledDark",
] as const;

const badgeBookingStateConfigs: Record<
	BadgeBookingStateCategory,
	BadgeBookingStateConfig
> = {
	Confirmed: {
		variantStyles: {
			borderColor: "backgroundSuccesslight",
			color: "textDefault",
			backgroundColor: "backgroundSuccesslight",
		},
		value: "Confirmed",
	},
	ConfirmedDark: {
		variantStyles: {
			color: "textLight",
			borderColor: "backgroundSuccessdark",
			backgroundColor: "backgroundSuccessdark",
		},
		value: "Confirmed",
	},
	Processing: {
		variantStyles: {
			color: "textDefault",
			borderColor: "backgroundWarningmedium",
			backgroundColor: "backgroundWarningmedium",
		},
		value: "Processing",
		icon: "Markers/Tooltip",
	},
	ProcessingDark: {
		variantStyles: {
			color: "textDefault",
			borderColor: "backgroundWarning",
			backgroundColor: "backgroundWarning",
		},
		value: "Processing",
		icon: "Markers/Tooltip",
	},
	Cancelled: {
		variantStyles: {
			color: "textDefault",
			borderColor: "backgroundCriticallight",
			backgroundColor: "backgroundCriticallight",
		},
		value: "Cancelled",
	},
	CancelledDark: {
		variantStyles: {
			color: "textLight",
			borderColor: "backgroundCriticaldark",
			backgroundColor: "backgroundCriticaldark",
		},
		value: "Cancelled",
	},
	Travelled: {
		variantStyles: {
			color: "textDefault",
			borderColor: "backgroundLightsubtle",
			backgroundColor: "backgroundLightsubtle",
		},
		value: "Travelled",
	},
	TravelledDark: {
		variantStyles: {
			color: "textLight",
			borderColor: "backgroundDarksubtle",
			backgroundColor: "backgroundDarksubtle",
		},
		value: "Travelled",
	},
};

export type BadgeBookingStateCategory =
	(typeof badgeBookingStateCategories)[number];

export interface BadgeBookingStateProps extends ComponentProps {
	category: BadgeBookingStateCategory;
}
export interface BadgeBookingStateConfig {
	variantStyles: SxStyleProp;
	icon?: IconProps["name"];
	toggledIcon?: IconProps["name"];
	value: string;
}

export const BadgeBookingState: React.FC<
	React.PropsWithChildren<BadgeBookingStateProps>
> = ({ className, "data-id": dataId, children, category }) => {
	const config = badgeBookingStateConfigs[category];

	return (
		<Label
			className={className}
			data-id={dataId}
			sx={{
				whiteSpace: "nowrap",
				paddingX: "3xs",
				borderRadius: "rounded",
				display: "inline-flex",
				alignItems: "center",
				gap: "4xs",
				borderWidth: "outlinedStrokeWeight",
				borderStyle: "solid",
				minHeight: "28px",
				...config.variantStyles,
				...(config.toggledIcon && {
					"svg:nth-of-type(2)": { display: "none" },
					":hover": {
						"svg:nth-of-type(1)": { display: "none" },
						"svg:nth-of-type(2)": { display: "block" },
					},
				}),
			}}
			variant="small"
		>
			{children ?? config.value}
			{config.icon && (
				<React.Fragment>
					<Icon size="16" name={config.icon} />
					{config.toggledIcon && <Icon size="16" name={config.toggledIcon} />}
				</React.Fragment>
			)}
		</Label>
	);
};
