import type React from "react";
import { Fragment } from "react";
import type { SxStyleProp } from "theme-ui";

import { type ComponentProps, Icon, type IconProps } from "@Components";
import type { Icons } from "@Tokens";

export interface ChoiceControlProps
	extends Pick<ComponentProps, "className" | "data-id"> {
	/**
	 * The type of the underlying `<input />` celement.
	 */
	type: "checkbox" | "radio";

	/**
	 * The value.
	 */
	checked?: boolean;

	/**
	 * Disables the control.
	 */
	disabled?: boolean;

	/**
	 * A callback which will be invoked when the control value changes.
	 */
	onChange: (value: boolean) => void;

	/**
	 * Icon for ON state.
	 */
	iconOn: Icons;

	/**
	 * Icon for OFF state.
	 */
	iconOff: Icons;

	/**
	 * Size of the icon.
	 */
	size?: IconProps["size"];
}

const controlStyles = (disabled: boolean, checked: boolean): SxStyleProp => {
	if (disabled) {
		return {
			color: "interactiveDisabledlight",
		};
	}

	return {
		color: checked ? "interactiveSelected" : "interactiveDefault",

		":hover": {
			color: "interactiveHover",
		},
	};
};

/**
 * This is a generic component which renders a custom checkbox/radio input.
 * This should not be used directly.
 */
export const ChoiceControl: React.FC<
	React.PropsWithChildren<ChoiceControlProps>
> = ({
	type,
	onChange,
	checked = false,
	disabled = false,
	iconOn,
	iconOff,
	size = "20",
	"data-id": dataId,
}) => (
	<Fragment>
		<input
			data-id={dataId}
			type={type}
			checked={checked}
			disabled={disabled}
			onChange={() => onChange(!checked)}
			tabIndex={-1}
			sx={{
				position: "absolute",
				height: 0,
				width: 0,
				opacity: 0,
			}}
		/>

		<Icon
			name={checked ? iconOn : iconOff}
			size={size}
			sx={controlStyles(disabled, checked)}
		/>
	</Fragment>
);
