import type React from "react";

import type { ComponentProps } from "@Components";
import type { ThemeDefinition } from "@Themes";
import type { CaptionVariants } from "@Tokens";

export interface CaptionProps extends ComponentProps {
	as?: "span" | "p";

	/**
	 * The Paragraph variant.
	 */
	variant: CaptionVariants;
}

export const Caption: React.FC<React.PropsWithChildren<CaptionProps>> = ({
	as = "span",
	variant,
	...props
}) => {
	const Wrapper = as;

	return (
		<Wrapper
			sx={(theme: ThemeDefinition) => theme.typography.caption[variant]}
			{...props}
		/>
	);
};
