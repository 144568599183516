import type React from "react";
import { createContext, useContext } from "react";

import { Pagination } from "./Pagination";

interface PaginationContextValue {
	currentPage: number;
	numberOfPages: number;
	maxNumberOfPagesToShow?: number;
	onPageSelect: (pageNumber: number) => void;
}

const PaginationContext = createContext<PaginationContextValue>({
	currentPage: 0,
	numberOfPages: 1,
	maxNumberOfPagesToShow: 6,
	onPageSelect: () => {},
});

interface ConnectedPaginationProps {
	onPagination?: () => void;
}

export const PaginationProvider: React.FC<
	React.PropsWithChildren<PaginationContextValue>
> = ({
	currentPage,
	numberOfPages,
	maxNumberOfPagesToShow,
	onPageSelect,
	children,
}) => (
	<PaginationContext.Provider
		value={{
			currentPage,
			numberOfPages,
			maxNumberOfPagesToShow,
			onPageSelect,
		}}
	>
		{children}
	</PaginationContext.Provider>
);

const usePagination = () => useContext(PaginationContext);

export const ConnectedPagination: React.FC<
	React.PropsWithChildren<ConnectedPaginationProps>
> = ({ onPagination }) => {
	const { currentPage, numberOfPages, onPageSelect, maxNumberOfPagesToShow } =
		usePagination();

	const scrollToTopItem = (pageIndex: number) => {
		onPageSelect(pageIndex);

		if (onPagination) {
			onPagination();
		}
	};

	return (
		<Pagination
			numberOfPages={numberOfPages}
			currentPage={currentPage}
			maxNumberOfPagesToShow={maxNumberOfPagesToShow}
			onPageSelect={scrollToTopItem}
		/>
	);
};
