import { type HastNode, hastToReact } from "@AuxiliaryComponents";
import { Heading, Link, Paragraph } from "@Components";
import type { ThemeDefinition } from "@Themes";
import React, { type FC } from "react";

export const TooltipHastContent: FC<{ content: HastNode }> = ({ content }) => (
	<div>
		{hastToReact(content, {
			h3: {
				Component: Heading,
				Props: {
					as: "h3",
					variant: "mediumbold",
					sx: {
						marginTop: 0,
						marginBottom: "xs",
					},
				},
			},
			p: {
				Component: Paragraph,
				Props: {
					variant: "extrasmall",
				},
			},
			a: {
				Component: Link,
				Props: {
					variant: "smallbuttonunderline",
					target: "_blank",
					"data-id": "popover-link",
					sx: (theme: ThemeDefinition) => ({
						color: "textPrimarydark",
						display: "inline-block",
						marginTop: "xs",
						fontSize: theme.typography.label.small.fontSize,
						fontWeight: theme.typography.label.smallbold.fontWeight,
					}),
				},
			},
		})}
	</div>
);
